@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Dongle&family=Mulish&family=Syncopate:wght@700&family=Blinker&family=Chakra+Petch:ital@1&family=Gloock&family=Poppins&family=Pangolin&family=Josefin+Sans:wght@200&family=Roboto&family=Racing+Sans+One&display=swap');
@font-face {
    font-family: "Roboto-Bold";
    src :url('../fonts/Roboto-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Roboto-Regular";
    src :url('../fonts/Roboto-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Akrobat-ExtraBold";
    src :url('../fonts/Akrobat-ExtraBold.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "RobotoCondensed-Bold";
    src :url('../fonts/RobotoCondensed-Bold.ttf');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: "EB Garamond SemiBold Italic";
    src :url('../fonts/EB Garamond SemiBold Italic.ttf');
    font-weight: normal;
    font-style: normal;
}
.ffes{
    font-family: "EB Garamond SemiBold Italic";
}

.ffRB{
    font-family: "RobotoCondensed-Bold";
}
.rso{
    font-family: 'Racing Sans One', sans-serif;
}
.jsan{
    font-family: 'Josefin Slab', serif;
}
::-webkit-scrollbar {  /* 滚动条整体部分 */
    width:6px;
    margin-right:0px
}
::-webkit-scrollbar:horizontal {
    height:4px;
    margin-bottom:4px
}
//   ::-webkit-scrollbar-track {  /* 外层轨道 */
//       border-radius: 10px;
//        width:6px;
//   }
//   ::-webkit-scrollbar-track-piece {  /*内层轨道，滚动条中间部分 */
//       background-color: #333333;
//       border-radius: 10px;
//        width:6px;
//   }
::-webkit-scrollbar-thumb {  /* 滑块 */
    width:2px;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover { /* 鼠标移入滑块 */
    background: #909090;
}
@keyframes scaleUpDown {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.ffrb{
    font-family: "Roboto-Bold";
}
.ffrg{
    font-family: "Roboto-Regular";
}
.swiper{
    position:relative;
    &:after{
        content:"";
        display:block;
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.5);
        z-index:10;
    }
}
.romantichuts{
    background-size: cover;
    position:absolute;
    width:100%;
    height: 100%;
    overflow: hidden;
    background:#EDF0F3;
}

.romantichuts.page{
    padding:0 12.5%;
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    bottom:0;
    box-sizing:border-box;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    @media(max-width:1024px){
        padding:0 .4rem;
        height: auto;
        display: block;
        position: static;
    }
    .romantichuts-header{
        padding-top: .3rem;
        width:100%;
        position:relative;
        z-index:100;
        box-sizing:border-box;
        .menu-box{
            position:absolute;
            width:100%;
            height:100vh;
            left:0;
            top:0;
            z-index:10;
            background:#fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding:0 .9rem;
            box-sizing:border-box;
            flex-direction: column;
            // transform:translateY(101%);
            opacity:0;
            visibility: hidden;
            transition:all 0.5s ease;
            &.show{
                visibility: visible;
                // transform:translateY(0);
                opacity:1;
            }

            a,span{
                display: flex;
                align-items:center;
                width: 100%;
                justify-content: center;
                height: 1.4rem;
                border-bottom:1px solid #1D192F;
                text-align:center;
                font-family: "Poppins";
                font-weight: 400;
                font-size: .32rem;
                color: #1D192F;
                line-height: .48rem;
                opacity:0.6;
                &.link-active{
                    opacity: 1;
                }
            }
        }
        @media(max-width:1024px){
            position:fixed;
            left:0;
            top:0;
            width:100%;
            background:#EDF0F3;
            padding:.4rem;
        }
        .logo .text{
            color:#1D192F;
            font-family: 'Poppins', sans-serif;
        }
        .links{
            .menu{
                width:.48rem;
                display:none;
                position:relative;
                z-index:101;
                @media(max-width:1024px){
                    display: block;
                }
            }
            a{
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: .24rem;
                color: #1D192F;
                line-height: .35rem;
                text-align: left;
                opacity: 0.6;
                transition:opacity 0.5s ease;
                @media(max-width: 1024px) {
                    display: none;
                }
                &.link-active{
                    opacity: 1;
                }
                &:hover{
                    opacity: 0.8;
                }
                &:last-child{
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content:"";
                    display: inline-block;
                    width:2px;
                    height: 16px;
                    margin:0 .12rem;
                    background:rgba(#1D192F,0.3);
                    vertical-align: middle;
                }
            }
        }
    }
    .pact{
        flex:1;
        overflow: auto;
        padding-top:1.1rem;
        display: flex;
        flex-direction: column;
        @media(max-width:1024px){
            padding-top: 1.6rem;
        }
        .pact-title{
            font-family: "Poppins";
            font-weight: bold;
            font-size: .48rem;
            color: #1D192F;
            line-height: .72rem;
            text-align: left;
            font-style: normal;
        }
        .pact-line{
            width: 1.72rem;
            height: .12rem;
            background: #1D192F;
            margin-top: .28rem;
            @media(max-width:1024px) {
                margin-top: .2rem;
            }
        }
        .mark{
            display: block;
            width: .28rem;
            height: .28rem;
            background:rgba(#1D192F,0.5);
            position:absolute;
            left: 12.5%;
            bottom:.5rem;
            @media(max-width: 1024px) {
                display: none;
            }

            &:after{
                content:"";
                width: .46rem;
                height:.46rem;
                display: block;
                background: #1D192F;
                position:absolute;
                left:.19rem;
                top:-.37rem;

            }
        }
        .pact-window{
            padding-left: 3.4rem;
            padding-right: 1rem;
            margin-top:.35rem;
            margin-bottom: .5rem;
            position:relative;
            flex:1;
            overflow: auto;
            @media(max-width:1200px){
                padding-left: 2rem;
            }
            @media(max-width:1024px){
                padding-left: 0;
                padding-right: 0;
            }

            .pact-info{
                overflow: auto;
                font-family: "Poppins";
                font-weight: 400;
                font-size: .24rem;
                color: rgba(#1D192F,0.5);
                line-height: .36rem;
                text-align: left;
                font-style: normal;
                p,h3{
                    padding-bottom: .2rem;
                }
                h3{
                    color:#1D192F;
                    font-size: .28rem;
                    line-height: .42rem;
                }
            }
            &:before{
                content:"";
                display: block;
                position:absolute;
            }
        }
    }
    .main{
        width:100%;
        flex:1;
        padding-bottom: .4rem;
        box-sizing:border-box;
        display: flex;
        align-items: end;
        position:relative;
        @media(max-width:1024px){
            display: block;
            min-height:100vh;
        }
        .swiper-box{
            display: none;
            margin-left:-0.4rem;
            margin-right:-0.4rem;
            @media(max-width:1024px){
                display: block;
            }
        }
        .banner{
            width:100%;
            position:absolute;
            left:0;
            bottom:0;
            @media(max-width:1500px){
                bottom:inherit;
                top:1rem;
            }
            @media(max-width:1024px){
                display: none;
            }
        }
        .bottom_box{
            display: flex;
            width:100%;
            align-items:end;
            justify-content: space-between;
            @media(max-width:1024px){
                display: block;
                padding-top: 1.8rem;
            }
            .bottom_left{
                .text{
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: .36rem;
                    color: #1D192F;
                    line-height: .55rem;
                    text-align: left;
                    @media(max-width:1024px){
                        text-align: center;
                    }
                }
                .copyright{
                    margin-top: .15rem;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: .16rem;
                    color: #1D192F;
                    line-height: .25rem;
                    text-align: left;
                    font-style: normal;
                    opacity: 0.5;
                    @media(max-width:1024px){
                        display: none;
                    }
                }

            }
            .bottom_right{
                display: flex;
                align-items:center;
                position:relative;
                z-index:10;
                @media(max-width:1024px){
                    justify-content: center;
                }
                .download{
                    width: 3.2rem;
                    height: auto;
                    margin-left: .3rem;
                    cursor: pointer;
                    @media(max-width:1024px){
                        margin-left:0;
                        margin-top: .6rem;
                    }
                    img{
                        max-width:100%;
                    }
                }
            }
        }

        .copyright-bottom{
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: .22rem;
            color: #1D192F;
            line-height: .33rem;
            text-align: center;
            font-style: normal;
            opacity: 0.5;
            width:100%;
            display: none;
            position:absolute;
            bottom:.35rem;
            left:0;

            @media(max-width:1024px){
                display: block;
            }
        }
    }
}

.pages{
    display: flex;
    align-items: center;
    justify-content: center;
    .progress-bar{
        width: 2.8rem;
        height:0.04rem;
        background:rgba(#1E1E1E,0.1);

        .progress-inner{
            height:100%;
            transition:all 0.3s ease;
            background:#1E1E1E;
        }
    }
    .right-box{
        margin-left:.25rem;
        span{
            padding:0 .12rem;
            font-family: "Poppins";
            font-weight: 600;
            font-size: .24rem;
            color:rgba( #1D192F,0.3);
            line-height: .35rem;
            &.swiper-pagination-customs-active{
                font-size:.36rem;
                line-height: .55rem;
                color:rgba( #1D192F,1);
            }
        }
    }
}
.alphaHiddenSeedWriting-swiper-box{
    width:100%;
    position:relative;
    height:5.8rem;
    margin-top:1rem;
    // padding:0 1.04rem;
    box-sizing: border-box;
    .swiper-slide{
        width:4.8rem;
        height: 4.8rem;
        overflow: hidden;
        img{
            max-width:100%;
        }
    }
    .swiper-container{
        height:100%;
    }
    // .swiper-button-prev{
    //     left:.25rem;
    //     background-image:url('../images/alphaHiddenSeedWritingContest/prev.webp') !important;
    //     background-size:100% 100% !important;
    // }
    // .swiper-button-next{
    //     background-image:url('../images/alphaHiddenSeedWritingContest/next.webp') !important;
    //     background-size:100% 100% !important;
    //     right:.25rem;
    // }
    .swiper-button-prev,.swiper-button-next{
        width: .66rem;
        height: 1.57rem;
        &:after{
            display: none;
        }
    }
}

.home{
     background-size: cover;
     position:absolute;
     width:100%;
     height: 100%;
     overflow: hidden;
     &.tvPage{
        .main > .tac{
            margin-top: -5%;
        }
        .footer{
            padding-top: .28rem;
            .copyright{
                span{
                    display: block;
                }
            }
            &:after{
                content:"";
                display: block;
                position:absolute;
                top:0;
                left:.64rem;
                width:calc(100% - 1.28rem);
                border-top:1px solid rgba(#fff,0.2);
            }
            @media(max-width:1200px){
                .flex-item{
                    margin-right: .5rem !important;
                }
            }
            @media(max-width:1024px){
                .flex-item{
                    padding-top: .2rem;
                }
                .copyright{
                    text-align: center;
                    padding-top: .2rem;
                    span{
                        display: inline-block;
                    }
                }
            }
            @media(max-width:500px){
                padding-left: .3rem;
                padding-right: .3rem;
                &:after{
                    content:"";
                    display: block;
                    position:absolute;
                    top:0;
                    left:.3rem;
                    width:calc(100% - .6rem);
                    border-top:1px solid rgba(#fff,0.2);
                }
            }
        }
        .w240{
            width: 2.4rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .w810{
            width: 8.1rem;
            margin-top: -.3rem;
            @media(max-width:900px){
                width:80%;
            }
        }
        .download{
            margin-top:.48rem;
            .download-item{
                width: 3.34rem;
                margin:0 .23rem;
                @media(max-width:600px){
                    margin:0 .1rem;
                }
            }
        }
     }
    .download{
        display:flex;
        align-items:center;
        margin-top:.64rem;
        justify-content: center;
        .download-item{
            width: 3.3rem;
            margin:0 .1rem;
            cursor: pointer;
            img{
                max-width:100%;
            }
        }
    }
    .swiper-container{
        position:absolute;
        width:100%;
        height: 100%;
        .img{
            width: 100%;
            height:100%;
            transition-origin:center center;
            object-fit: cover;
            transform:scale(1.1) translate(10px, 0);
            transition:transform 7.6s linear;
        }
        .swiper-slide-active{
            .img{
                transform:scale(1) translate(0px, 0);
                transition-delay:.2s;
                //animation: scaleUpDown 10.6s .3s forwards ease-out;
            }
        }
    }
    .main{
        height: 100%;
        position:absolute;
        left:0;
        top:0;
        z-index:1000;
        display:flex;
        align-items: center;
        width:100%;
        justify-content: center;
    }
}
.header-top{
    z-index:1000;
}
.footer{
    z-index:1000;
}
.link{
    color:#08c;
    cursor: pointer;
}
.copyright{
    @media(max-width: 750px){
        margin-top:15px;
    }
}
.line{
    position:absolute;
    bottom:7%;
    left:0;
    text-align: center;
    width:100%;

    span{
        width: 0.3rem;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        height: 0.06rem;
        background: #E8E8E8;
        position:relative;
        z-index:100;
        border-radius: 3px;
        margin:0 0.15rem;
        &.line-cur{
            background:var(--primary-color);
        }
    }
}


.wrapper{
    height:0.6rem;
    position:relative;
    display:flex;
    align-items:center;
    justify-content: center;
    font-size: 0.24rem;
    color:#212121;
    font-weight: 500;
    .logo img{
        width:0.6rem;
        position:absolute;
        left:0;
        top:0;
    }

}
.wrap{
    h2{
        font-size: .28rem;
        line-height: .5rem;
    }
    em{
        font-style: normal;
    }
}

.f-line{
    width:100%;
    height:1px;
    background:#E6E9F0;
    margin-top:0.4rem;
    margin-bottom: 5px;
}

.page-home{
    //background:url('../images/bg.png') no-repeat center center;
    background-size: cover;
}

.modal-title{
    span{
        font-size: 14px;
        color:#999;
        margin-left:10px;
    }
}

textarea{
    resize:none !important;
}

.ant-form-item-label > label{
    min-height:32px !important;
    height:auto !important;
}

.ffae{
    font-family: "Akrobat-ExtraBold";
}

.home-header{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    padding-top: .15rem;
    padding-bottom: .15rem;
    box-sizing: border-box;
    background: rgba(0,0,0,0.29) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.59) 100%);
    z-index:1001;
    &.theme3{
        background:none;
        .left-box{
            img{
                width: .5rem;
                @media(max-width:600px){
                    width: .6rem;
                }
            }
            @media(max-width:600px){
               .fz20{
                   font-size:.28rem !important;
                   line-height: .4rem;
               }
            }
        }

        .home-header-center{
            width:auto;
            padding-left: 10.93%;
            padding-right:10.93%;
            @media(max-width:1300px){
                padding-left: 7%;
                padding-right: 7%;
            }

            a{
                padding:.14rem .24rem;
                background:transparent;
                border-radius:.2rem;
                &:hover{
                    color:#fff !important;
                    background:rgba(#fff,0.2);
                }
            }
        }
    }
    .home-header-center{
        width:1216px;
        margin-left: auto;
        margin-right: auto;
        @media(max-width:1300px){
            width:90%;
        }
        .top-link{
          @media(max-width:750px){
              display: none;
          }
        }
    }
}
.opa8{
    opacity: 0.82;
}
.home-style4{
    .main{
        padding-left: 10.93%;
        padding-right:10.93%;
        display: flex;
        justify-content: flex-end;
        @media(max-width:700px){
            align-items: baseline;
            padding-top:1.2rem;

        }
        .people{
            position:absolute;
            min-height:78.7%;
            width:44.27%;
            left:10.93%;
            bottom:6%;
            @media(max-width:1400px){
                left:5.93%;
            }
            @media(max-width:1100px){
                width:50%;
                //bottom:0;
                left:0;
                img{
                    position:absolute;
                    top:inherit !important;
                    right:inherit !important;
                }
            }
            @media(max-width:850px){
                width:44%;
            }
            @media(max-width:500px){
                width:70%;
            }
            img{
                position:absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                //margin:auto;
                max-height:100%;
                max-width:100%;
            }
        }
        .download{
            justify-content: flex-end;
        }
    }
    .footer-homebox{
        position:absolute;
        width: 100%;
        right:0;
        bottom:.5rem;
        z-index:1112;
        text-align:center;
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                a{
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
        }
        .copyright{
            font-size: .16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .26rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    @media(max-width:1300px){
        .main{
            padding-left: 7%;
            padding-right: 7%;
            .title{
                font-size: 1.2rem !important;
                line-height: 1.6rem !important;
            }
            .desc{
                font-size:.4rem !important;
            }
        }
    }
    .img{
        width:100%;
        height:100%;
    }
    .right-main{
        text-align: right;
        @media(max-width:700px){
            position:relative;
            z-index:200;
        }
    }
}
.home-style3{
    .main{
        padding-left: 10.93%;
        padding-right:10.93%;
        display: flex;
        justify-content: flex-end;
        @media(max-width:700px){
            align-items: baseline;
            padding-top:1.2rem;

        }
        .shu{
            position:absolute;
            min-height:78.7%;
            width:44.27%;
            left:2.1%;
            bottom:6%;
            @media(max-width:1024px){
                width:50%;
                //bottom:0;
                left:0;
                img{
                    position:absolute;
                    top:inherit !important;
                    right:inherit !important;
                }
            }
            @media(max-width:700px){
                width:70%;
            }
            @media(max-width:500px){
                width:80%;
            }
            img{
                position:absolute;
                left:0;
                top:0;
                right:0;
                bottom:0;
                //margin:auto;
                max-height:100%;
                max-width:100%;
            }
        }
        .download{
            justify-content: flex-end;
        }
    }
    .footer-homebox{
        position:absolute;
        width: 100%;
        right:0;
        bottom:.5rem;
        z-index:1112;
        text-align:center;
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                a{
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                    font-family: PingFangSC-Semibold, PingFang SC;
                }
            }
        }
        .copyright{
            font-size: .16rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .26rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    @media(max-width:1300px){
        .main{
            padding-left: 7%;
            padding-right: 7%;
            .title{
                font-size: 1.2rem !important;
                line-height: 1.6rem !important;
            }
            .desc{
                font-size:.4rem !important;
            }
        }
    }
    .img{
        width:100%;
        height:100%;
    }
    .right-main{
        text-align: right;
        @media(max-width:700px){
            position:relative;
            z-index:200;
        }
    }
}
.home-style2{
    .img{
        width:100%;
        height:100%;
    }
    .footer-homebox{
        position:absolute;
        width: 1216px;
        right:0;
        left:50%;
        transform:translateX(-50%);
        bottom:.5rem;
        z-index:1112;
        text-align:right;
        @media(max-width:1300px){
            width: 90%;
        }
        @media(max-width:750px){
            text-align: center;
            bottom:.3rem;
        }
        .top-link{
            display: none;
            @media(max-width:750px){
                display: block;
                .mr77{
                    margin:0 .2rem;
                    font-size: .24rem;
                }
                a{
                    font-size: .24rem;
                }
                .mr77{
                    margin:0 .4rem;
                    font-size: .24rem;
                }
            }
        }
        .copyright{
            font-size: .18rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color:rgba(#fff,0.6);
            line-height: .25rem;
            margin-top: 0;
            @media(max-width:750px){
                margin-top:.15rem;
                font-size: .24rem;
            }
        }
    }
    .main{
        width: 1216px;
        left:50%;
        transform:translateX(-50%);
        bottom:0;
        display: block;
        height:83.6%;
        top:inherit;
        @media(max-width:1300px){
            width:90%;
        }
        @media(max-width:750px){
            height:100%;
        }
        .left-main{
            padding-top:.8rem;
            padding-right: 560px;
            position:relative;
            z-index:1111;
            @media(max-width:1300px){
                padding-right: 46.05%;
            }
            @media(max-width:750px){
                padding-right: 0;
            }
            .title{
                text-shadow: 0px 11px 36px rgba(0,0,0,0.5);
            }
        }
        .download{
            //display: block;
            text-align:left;
            justify-content: flex-start;
            .download-item{
                width: 3.3rem;
                margin-left: 0;
            }
        }
        .right-box{
            //width:560px;
            position:absolute;
            right:0;
            bottom:0;
            height:100%;
            @media(max-width:1300px){
                width:46.05%;
                height:auto;
            }
            @media(max-width:750px){
                width:40%;
            }
            @media(max-width:600px){
                width:65%;
                right:-5%;
            }
            img{
                width:100%;
               //@media(max-width:750px){
               //    position:absolute;
               //    bottom:0;
               //    right:0;
               //}
            }
        }
    }
    .footer-box{
        @media(max-width:750px){
            position:fixed;
            bottom:.3rem;
            left:0;
            width:100%;
            text-align: center;

        }
    }
}


.ffps{
    font-family: PingFangSC-Semibold, PingFang SC;
}
.page{
    height: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}
.template4{
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    .footer-box{
        position:absolute;
        width:100%;
        left:0;
        bottom:0;
        text-align: center;
        .download{
            display: flex;
            align-items: center;
            justify-content: center;
            margin:0 .2rem;
        }
        .download-item{
            width: 3.7rem;
            img{
                max-width:100%;
            }
        }
        .desc{
            font-size: .9rem;
            font-weight: normal;
            color: #000000;
            line-height: 1.01rem;
            margin-top: 5%;
        }
    }
    .top-box{
        position:absolute;
        left:0;
        right: 0;
        top: 0;
        .appName{
            text-align: center;
            text-transform: uppercase;
            color:#000;
            font-weight: bold;
            line-height:0.82;
        }
        .links{
            font-size: .28rem;
            margin-top: 6%;
            color:#000000;
            width:100%;
            display: flex;
            padding:0 .18rem;
            box-sizing: border-box;
            align-items:center;
            justify-content: space-between;
            a{
                color:#000;
                opacity: 0.7;
                &:hover{
                  opacity: 1;
                }
            }
        }
    }

}

.template8{
    min-height:100vh;
    overflow: hidden;
    position:relative;
    background-position:right center;
    background-size:auto 100%;
    background-repeat: no-repeat;
    @media(max-width:1200px){
        background-position:-50% center;
    }
    @media(max-width:700px){
        background:#fff;
        background-image:none !important;
    }
    .top-box{
        position:absolute;
        left:0;
        right:0;
        top:.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:700px){
            flex-direction: column;
        }
        .top-left{
            display: flex;
            align-items: center;
            padding-left: 1.03rem;
            @media(max-width:1024px){
                padding-left: .3rem;
            }
            .logo{
                width: .5rem;
                height: .5rem;
                background: #D8D8D8;
                border-radius: .08rem;
                overflow: hidden;
                img{
                    width:100%;
                }
            }
            .appName{
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: .33rem;
                margin-left: .14rem;
            }
        }
        .links{
            padding-right: .3rem;
            @media(max-width:700px){
                padding-right: 0;
                margin-top:.3rem;
            }
            a{
                margin:0 .7rem;
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: .33rem;
                position:relative;
                transition:color 0.3s ease;
                @media(max-width:1200px){
                    margin:0 .4rem;
                }
                @media(max-width:1024px){
                    margin:0 .2rem;
                }

                &:hover{
                    color:#4B27AC;
                    &:after{
                        transform-origin:left center;
                        transform:scaleX(1);
                    }
                }
                &:after{
                    content:'';
                    display: block;
                    position:absolute;
                    width:100%;
                    left:0;
                    bottom: 0;
                    height: .04rem;
                    background: #4B27AC;
                    border-radius: .03rem;
                    transform-origin:right center;
                    transform:scaleX(0);
                    transition:transform 0.3s ease;
                }
            }
        }
    }
    .cont-box{
        padding-top: 12.5%;
        padding-left:1.03rem;
        @media(max-width:1024px){
            padding-left: .3rem;
            padding-top:3rem !important;
        }
        .appName{
            font-size: 1.26rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #323232;
            line-height: 1.77rem;
        }
        .desc{
            font-size: .36rem;
            font-family: PingFangSC, PingFang SC;
            color: #818181;
            line-height: .5rem;
        }
        .download{
            margin-top:.96rem;
            @media(max-height:950px){
                margin-top:.5rem;
            }
            @media(max-width:700px){
                .download-item{
                    width:3rem;
                }
            }
            .download-item{
                width: 3.98rem;
            }
            img{
                max-width:100%;
            }
        }
    }
}



.template4-pact{
    .title{
        height: 1.26rem;
        font-size: 1.2rem;
        font-family: 'Bebas Neue';
        line-height: .8;
        color: #262626;
        text-align: center;
    }
    .pact-cont{
        font-size: .24rem;
        color: #262626;
        line-height: .4rem;
        width:1210px;
        margin-left: auto;
        margin-right: auto;
        padding-top: .4rem;
        padding-bottom:.2rem;
        font-family: 'Playfair Display', serif;
        strong{
            margin-right: .05rem;
        }
        h2,h3,h4{
            margin-top: .2rem;
            font-family: 'Playfair Display', serif;
            font-weight: bold;
        }
        h2{
            font-size: .34rem;
        }
        h3{
            font-size: .32rem;
        }
        h4{
            font-size: .3rem;
        }
        @media(max-width:1300px){
            width:90%;
        }
    }
}

.template8-pact{
    width:100%;
    height:100vh;
    overflow: hidden;
    background-position:right center;
    background-repeat: no-repeat;
    background-size:auto 100%;
    display: flex;
    padding-left: 13.54%;
    padding-right: .4rem;

    @media(max-width:1500px){
        padding-left: 10%;

        .title{
            margin-right: 1rem;
            p{
                font-size:1.3rem !important;
                line-height: 0.9rem !important;
            }
        }
    }

    @media(max-width:1024px){
        padding-left: .4rem;
        display: block;
        overflow: auto !important;
        .left-page{
            width: 0 !important;
            display: none;
        }
        .title{
            margin-right: 0;
            padding-top:.5rem !important;
            p{
                font-size:1.3rem !important;
                line-height: 0.9rem !important;
            }
        }
        .right-content{
            height:auto !important;
            margin-top:.4rem;
            flex:inherit;
            div{
                position:relative !important;
                height:auto !important;
            }
        }
    }
    .title{
        margin-right:1.5rem;
        padding-top:1.48rem;

       p{
           font-size: 1.56rem;
           font-family: PingFangSC, PingFang SC;
           color: #21007A;
           line-height: 1.02rem;
           margin-bottom: 0;


       }
    }
     .right-content{
         margin-top:.98rem;
         flex:1;
     }
    .pact-cont{
        //overflow: auto;
        font-size: .24rem;
        color: #170056;
        line-height: .4rem;
        margin-left: auto;
        margin-right: auto;
        padding-bottom:.2rem;
        p{
            padding-bottom: .2rem;
        }
        strong{
            margin-right: .05rem;
        }
        h2,h3,h4{
            margin-top: .2rem;
            font-weight: bold;
        }
        h2{
            font-size: .34rem;
        }
        h3{
            font-size: .32rem;
        }
        h4{
            font-size: .3rem;
        }
    }

}

.template9{
    min-height:100vh;
    overflow: hidden;
    position:relative;
    background: #000;
    .box{
        height: 100vh;
        .logoBox{
            position: absolute;
            display: flex;
            align-items: center;
            left: 0.24rem;
            top: 0.24rem;
            color: #fff;
            font-size: 0.24rem;
            .logo{
                width: .5rem;
                height: .5rem;
                background: #D8D8D8;
                border-radius: .08rem;
                overflow: hidden;
                margin-right: 0.1rem;
                img{
                    width:100%;
                }
            }
        }
        .new1{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .newTab{
                width: 4.32rem;
                height: 6.85rem;
                background: #fff;
                margin-right: 0.08rem;
                align-self:flex-start;
                flex-shrink: 0;
                &:last-child{
                    margin-right: 0;
                }
            }
            .t1{
                background: url('../images/template9/bj1.png') no-repeat;
                background-size: contain;
                &:hover{
                    background: url('../images/template9/bj1_1.png') no-repeat;
                background-size: contain;
                }
            }
            .t2{
                width: 5.16rem;
                height: 6.85rem;
                background: url('../images/template9/bj2.png') no-repeat;
                background-size: contain;
                align-self:flex-end;
                &:hover{
                    background: url('../images/template9/bj2_1.png') no-repeat;
                background-size: contain;
                }
            }
            .t3{
                width: 5.16rem;
                height: 6.85rem;
                background: url('../images/template9/bj3.png') no-repeat;
                background-size: contain;
                &:hover{
                    background: url('../images/template9/bj3_1.png') no-repeat;
                background-size: contain;
                }
            }
            .t4{
                background: url('../images/template9/bj4.png') no-repeat;
                background-size: contain;
                align-self:flex-end;
                &:hover{
                    background: url('../images/template9/bj4_1.png') no-repeat;
                background-size: contain;
                }
            }
        }
        .links{
            position: absolute;
            top: 0.24rem;
            right: 0.24rem;
            z-index: 2;
            // padding-right: .3rem;
            display: flex;
            @media(max-width:700px){
                padding-right: 0;
                margin-top:.3rem;
            }
            .newLine{
                width: 0.03rem;
                background: rgba(255,255,255,0.54);
                border-radius: 0.02rem;
                margin-left: 0.4rem;
            }
            a{
                margin-left: 0.4rem;
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #C3FEFF;
                line-height: .33rem;
                position:relative;
                transition:color 0.3s ease;
                @media(max-width:1200px){
                    margin:0 .4rem;
                }
                @media(max-width:1024px){
                    margin:0 .2rem;
                }

                &:hover{
                    // color:#4B27AC;
                    &:after{
                        transform-origin:left center;
                        transform:scaleX(1);
                    }
                }
                &:after{
                    content:'';
                    display: block;
                    position:absolute;
                    width:100%;
                    left:0;
                    bottom: 0;
                    height: .04rem;
                    background: #fff;
                    border-radius: .03rem;
                    transform-origin:right center;
                    transform:scaleX(0);
                    transition:transform 0.3s ease;
                }
            }
        }
    }
    .download{
        position: absolute;
        right: 0.34rem;
        top: 0.9rem;
    }
    .newAppName{
        position: fixed;
        bottom: 1rem;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
        line-height: 0.42;
        z-index: 2;
    }
}
.template9-pact{
    background: #000;
    color: #fff;
    padding-left: 5%;
    .title{
        padding-top: 0.3rem;
        margin-right: 0 !important;
        p{
            color: #fff;
            line-height: 1 !important;
            &:first-child{
                margin-left: 1.2rem;
                margin-bottom: 0.04rem;
            }
        }
        .p1{
            margin-left: 0.4rem;
        }
    }

    .right-content{
        margin-top: 3.4rem;
        .pact-cont{
            color: #fff;
        }
    }
    @media(max-width:1024px){
        .right-content{
            margin-top: 0.4rem;
        }
    }
}
.template10 {
    background-size: 100% 100%;
    .newTemp10{
        width: 80%;
        // height: 90%;
        margin: 1rem auto 1rem;
        background: rgba(255,255,255,0);
        border-radius: 0.48rem;
        border: 0.02rem solid rgba(255, 255, 255, 0.6);
        // border-image: linear-gradient(146deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.29)) 2 2;
        backdrop-filter: blur(0.26rem);
        padding-right: 1rem;
        box-shadow: -0.04rem -0.04rem 0.08rem -0.01rem rgba(255, 255, 255, 0.62);
        .top-box{
            .appName{
                color: #C6A1FF;
            }
        }
        .cont-box{
            text-align: right;
            padding-top: 18%;
            .appName{
                color: #A46AFE;
            }
            .desc{
                color: #C6A1FF;
            }
            .download{
                margin-bottom: 0.4rem;
                text-align: -webkit-right;
            }

        }

        .links{
            a{
                color: #A495FF;
            }
        }
    }
    .copyright{
        width: 100%;
        position: fixed;
        left: 50%;
        bottom: 0.4rem;
        transform: translate(-50%);
        text-align: center;

        color: #A495FF;
        font-size: 0.16rem;
    }
}
.template10-pact {
    background-size: 100% 100%;
    padding: 0 8%;
    .pactNew{
        width: 100%;
        background: rgba(255,255,255,0.34);
        border-radius: 0.48rem;
        border: 2px solid rgba(255, 255, 255, 0.29);
        backdrop-filter: blur(0.50rem);
        margin: 1rem 0;

        margin: 1.2rem auto ;
        padding: 0.4rem;
        overflow: hidden;
        .title{

            padding: 0;
            p{
                font-size: 0.8rem;
                line-height: 0.8rem;
                color: #A46AFE;
            }
        }
        .newFlx{
            display: flex;
            .left-page{
                width: 30%;
                margin-top: 2.2rem;
                p{
                    font-size: 0.22rem;
                    color: #9255FF;
                    margin-bottom: 1.4rem;
                }
            }
        }
        .right-content{
            margin-top: 0.5rem;
        }
        .pact-cont{
            color: #9255FF;
            p{
            padding-bottom: 0;
            }
        }
    }
}
.template11{
    min-height:100vh;
    overflow: hidden;
    position:relative;
    // background-position:right center;
    // background-size:auto 100%;
    // background-repeat: no-repeat;
    background: #000;
    @media(max-width:1200px){
        background-position:-50% center;
    }
    @media(max-width:700px){
        background:#fff;
        background-image:none !important;
    }
    .top-box{
        position:absolute;
        left:0;
        right:0;
        top:.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:700px){
            flex-direction: column;
        }
        .top-left{
            display: flex;
            align-items: center;
            padding-left: 0.5rem;
            @media(max-width:1024px){
                padding-left: .3rem;
            }
            .logo{
                width: .5rem;
                height: .5rem;
                background: #D8D8D8;
                border-radius: .12rem;
                overflow: hidden;
                img{
                    width:100%;
                }
            }
            .appName{
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #fff;
                line-height: .33rem;
                margin-left: .14rem;
                @media(max-width:1024px){
                    color: #000;
                }
            }
        }
        .links{
            padding-right: .3rem;
            display: flex;
            .newLink{
                width: 0.03rem;
                background: #fff;
                margin-left: 0.7rem;
                opacity: 0.8;
            }
            @media(max-width:700px){
                padding-right: 0;
                margin-top:.3rem;
            }
            a{
                margin-left: 0.7rem;
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #fff;
                line-height: .33rem;
                position:relative;
                transition:color 0.3s ease;
                opacity: 0.8;
                @media(max-width:1200px){
                    margin:0 .4rem;
                }
                @media(max-width:1024px){
                    margin:0 .2rem;
                    color: #000;
                }

                &:hover{
                    color:#fff;
                    opacity: 1;
                    // &:after{
                    //     transform-origin:left center;
                    //     transform:scaleX(1);
                    // }
                }
                // &:after{
                //     content:'';
                //     display: block;
                //     position:absolute;
                //     width:100%;
                //     left:0;
                //     bottom: 0;
                //     height: .04rem;
                //     background: #4B27AC;
                //     border-radius: .03rem;
                //     transform-origin:right center;
                //     transform:scaleX(0);
                //     transition:transform 0.3s ease;
                // }
            }
        }
    }
    .cont-box{
        // padding-top: 1rem;
        margin-top: 1rem;
        margin-right:12%;
        // height: "calc(100vh - .98rem)";
        background: url('../images/template11/homebg.png') no-repeat;
        // background-size: auto 100%;
        position: relative;
        @media(max-width:1024px){
            padding-left: 0rem;
            margin-right: 0;
            padding-top:3rem !important;
            background:none;
        }
        .desc{
            position: absolute;
            right: 0rem;
            bottom: 2.7rem;
            font-size: .24rem;
            font-family: PingFangSC, PingFang SC;
            color: #818181;
            line-height: .5rem;
        }
        .download{
            position: absolute;
            right: 1rem;
            bottom: 3rem;
            margin-top:.96rem;
            @media(max-height:950px){
                margin-top:.5rem;
            }
            @media(max-width:700px){
                .download-item{
                    width:3rem;
                }
            }
            .download-item{
                width: 3.98rem;
            }
            img{
                max-width:100%;
            }
        }
    }
    .newAuto{
        position: absolute;
        bottom: 0;
        color: #fff;
        width: 100%;
        // margin-left: 12%;
        font-size: 2.8rem;
        text-align: right;
        line-height: 1;
        @media(max-width:950px){
            font-size: 1.5rem;
            color: #000;
        }
    }
}
.template11-pact{
    background: #000;
    .title{
        margin-top: 0.2rem;
        margin-right: 0;
        padding-top: 0;
        padding-right: 1.4rem;
        margin-right: 0.5rem;
        border-right: 0.04rem solid rgba(195,254,255,0.44);
        .top-left{
            display: flex;
            align-items: center;
            // padding-left: 0.5rem;
            @media(max-width:1024px){
                padding-left: .3rem;
            }
            .logo{
                width: .5rem;
                height: .5rem;
                background: #D8D8D8;
                border-radius: .12rem;
                overflow: hidden;
                img{
                    width:100%;
                }
            }
            .appName{
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #fff;
                line-height: .33rem;
                margin-left: .14rem;
                // @media(max-width:1024px){
                //     color: #000;
                // }
            }
        }
    }
    .pact-cont{
        color: #fff !important;
        padding-bottom: 0.6rem;

    }
    .right-content{
        margin-top: 2rem;
        .privacy{
            font-size: 1.24rem;
            padding-top: 0.4rem;
            height: auto !important;
            margin:0;
            margin-bottom:1.1rem;
            @media(max-width:1024px){
                font-size: 0.5rem;
                padding-top: 0;
            }

        }
        p{
            color: #C3FEFF !important;
            padding-bottom: 0 !important;
            font-size:.24rem;
            font-family: 'EB Garamond';
            @media(max-width:500px){
                font-size: 0.3rem;
                padding-top: 0;
            }
        }
    }
}
.bjcolor{
    background: #DFCCC8;
}
.template12{
    min-height:100vh;
    overflow: hidden;
    position:relative;
    background-position:right center;
    background-size:auto;
    background-repeat: no-repeat;
    @media(max-width:1200px){
        // background-position:-50% center;
    }
    @media(max-width:700px){
        background:#fff;
        background-image:none !important;
    }
    .top-box{
        position:absolute;
        left:0;
        right:0;
        top:.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width:700px){
            flex-direction: column;
            align-items: flex-start;
        }
        .top-left{
            display: flex;
            align-items: center;
            padding-left: 1.03rem;
            @media(max-width:1024px){
                padding-left: .3rem;
            }
            .logo{
                width: .5rem;
                height: .5rem;
                // background: #D8D8D8;
                border-radius: .08rem;
                overflow: hidden;
                img{
                    width:100%;
                }
            }
            .appName{
                font-size: .24rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: .33rem;
                margin-left: .14rem;
            }
        }

    }
    .cont-box{
        padding-top: 1.43rem;
        padding-left:1.8rem;
        @media(max-width:1024px){
            padding-left: .3rem;
            padding-top:3rem !important;
        }
        .appName{
            font-size: 0.9rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #323232;
            margin-bottom: 0;
            line-height: 1;
            // line-height: 1.77rem;
        }
        .desc{
            font-size: .24rem;
            font-family: PingFangSC, PingFang SC;
            color: #818181;
            line-height: .5rem;
            margin-bottom: 0;
        }
        .download{
            margin-top:.2rem;
            @media(max-height:950px){
                margin-top:.2rem;
            }
            @media(max-width:700px){
                .download-item{
                    width:3rem;
                }
            }
            .download-item{
                width: 3.98rem;
            }
            img{
                max-width:100%;
            }
        }
    }
    .links{
        position: absolute;
        right: 0;
        bottom: 0.2rem;
        padding-right: .3rem;
        @media(max-width:700px){
            padding-right: 0;
            margin-top:.3rem;
        }
        a{
            margin:0 .7rem;
            font-size: .24rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #585858;
            line-height: .33rem;
            position:relative;
            transition:color 0.3s ease;
            @media(max-width:1200px){
                margin:0 .4rem;
            }
            @media(max-width:1024px){
                margin:0 .2rem;
            }

            &:hover{
                color:#000;
                // &:after{
                //     transform-origin:left center;
                //     transform:scaleX(1);
                // }
            }
            // &:after{
            //     content:'';
            //     display: block;
            //     position:absolute;
            //     width:100%;
            //     left:0;
            //     bottom: 0;
            //     height: .04rem;
            //     background: #4B27AC;
            //     border-radius: .03rem;
            //     transform-origin:right center;
            //     transform:scaleX(0);
            //     transition:transform 0.3s ease;
            // }
        }
    }
}
.template12-pact{
    width:100%;
    height:100vh;
    overflow: hidden;
    background: #DFCCC8;
    .bg2{
        width: 100%;
        height: 1.58rem;
        background: url('../images/template12/bg2.png') no-repeat;
        background-size: auto 100%;
    }
    .privacy{
        display: flex;
        align-items: center;
        margin-top: 0.1rem;
        padding-bottom: 0.4rem;
        height: calc(100vh - 1.58rem);
        @media (max-width:1024px) {
            flex-direction: column;
        }
        .title{
            width: 46%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.4rem;
            color: #323232;
            flex-shrink: 0;
            text-align: right;

            p{
                margin-bottom: 0;
                line-height: 1;
            }
        }
        .right-content{
            width: 100%;
            height: 100%;
            padding: 0.2rem 0.4rem 0;
            .pact-cont{
                // margin-top: 0.4rem;
            }
            p{
                margin-bottom: 0.2rem;
            }
        }
    }
}
.template13 {
    background: #FFFEA7;
    .icon13{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4.68rem;
        background: url('../images/template12/icon_13.png') no-repeat;
        background-size: cover;
        z-index: 0;
    }
    .links{
        position: absolute;
        right: 0;
        bottom: 0.2rem;
        padding-right: .3rem;
        @media(max-width:700px){
            padding-right: 0;
            margin-top:.3rem;
            top: 7rem;
        }
    }
    .cont-box{
        padding-left: 0;
        margin-bottom: 1rem;

        @media (max-width:1024px) {
            padding-top: 2rem !important;
        }
        .appName{
            position: sticky;
            z-index: 3;
            font-size: 2rem;
            @media(max-width:700px){
                font-size: 1rem;
            }
        }
        .download{
            position: relative;
            .download-item{
                margin-left: 40%;
                width: 4.44rem;
                margin-top: -0.8rem;
                position: relative;
                z-index: 4;
                // @media(max-width:1024px){
                //     margin-left: 1rem;
                // }


            }
        }
    }
}
.template13-pact{

    background: #FFFEA7;
    .policy{
        padding: 0 0.4rem;
        font-size: 0.79rem;
        color: #262626;
        font-family: 'Krona One', sans-serif;
        @media(max-width:700px){
            // font-size: 0.67rem;
        }
    }
    .privacy{
        height: 100%;
        align-items: flex-start;
        .right-content{
            padding-bottom: 1.2rem;
            @media(max-width:700px){
                padding-bottom: 2.2rem;
            }
            p{
                margin-bottom: 0.24rem;
                padding-bottom: 0;
                font-size: 0.24rem;
                color: #373737;
            }
            .subTitle{
                font-size: 0.28rem;
                color: #373737;
                line-height: 0.28rem;
                font-weight: bold;
                margin-bottom: 0.18rem;
            }
        }
        .title{
            width: 28%;
            justify-content: flex-start;

            @media (max-width:1024px) {
                width: 100%;
                flex-direction: row;

            }
            .p1{
                width: 3.8rem;
                height: 1.3rem;
                background: url('../images/template12/p1_13.png') no-repeat;
                background-size: contain;
                margin-bottom: 1rem;
                @media (max-width:1024px) {
                    margin-bottom: 0rem;

                }
            }
            .p2{
                width: 3.8rem;
                height: 1.3rem;
                background: url('../images/template12/p2_13.png') no-repeat;
                background-size: contain;
            }
        }
    }
    .terms13{
        position: relative;
        width: 70%;
        margin: 0 auto;
        background: #fff;
        box-shadow: -4px 5px 12px 0px rgba(0,0,0,0.21);
        @media (max-width:1024px) {
            width: 92%;
        }
        .ding{
            width: 0.5rem;
            height: 0.5rem;
            background: url('../images/template12//ding_13.png') no-repeat;
            background-size: contain;
            position: absolute;
            right: -0.3rem;
            top: -0.3rem;
        }
    }
}
.template14{
    background-position-x: left;
    .top-box{
        .top-left{
            .appName{
                color: #fff;
                @media(max-width:700px){
                    color: #000;
                }
            }
        }
    }
    .links{
        position: initial;
        display: flex;
        margin-right: 1rem;
        @media(max-width:700px){
            margin-right: 0;
            margin-top:.3rem;
        }
        .newLine{
            width: 0.03rem;
            background: rgba(255,255,255,0.54);
            border-radius: 0.02rem;
            margin-left: 0.5rem;
        }
        a{
            margin:0 0 0 0.5rem;
            font-size: .24rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: rgba(255,255,255,0.54);
            line-height: .33rem;
            position:relative;
            transition:color 0.3s ease;
            @media(max-width:1024px){
                margin:0 .2rem;
                color: #000;
            }

            &:hover{
                color:#FFDB8D;

            }

        }
    }
    .cont-box{
        position: absolute;
        right: 1rem;
        top: 1.6rem;
        text-align: right;
        @media (max-width: 1024px){
            right: 0;
        }
        .appName{
            font-size: 1.6rem;
            font-family: EB Garamond SemiBold Italic;
            line-height: 1;
            color: #FEDF9C;
            text-shadow: 0px 5px 9px rgba(111,74,14,0.51);
            letter-spacing: 6px;
        }
        .desc{
            font-size: 0.36rem;
            font-family: PingFangSC, PingFang SC;
            color: rgba(255,242,215,0.66);
            line-height: 0.80rem;
        }
        .download{
            display: flex;
        }
    }
}
.template14-pact{
    padding-right: 0;
    background-position-x: left;
    @media (max-width:1024px) {
        padding: 0;
    }

    .title{
        width: 36%;
        text-align: right;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        p{
            font-family: EB Garamond SemiBold Italic;
            font-size: 1rem;
            color: #FEDF9C;
            line-height: 1.5;
        }


    }
    .right-content{
        background: #FFF9EC;
        margin-top: 0;
        border-radius: 0.48rem 0 0 0.48rem;
        padding: 0.4rem;
        .pact-cont{
            p{
                margin-bottom: 0.24rem;
                padding-bottom: 0;
                font-size: 0.24rem;
                color: #373737;
            }
            .subTitle{
                font-size: 0.28rem;
                color: #373737;
                line-height: 0.28rem;
                font-weight: bold;
                margin-bottom: 0.2rem;
            }
        }

    }
}
.template15{
    background: #D7DFE2;
    .bjTop{
        position: absolute;
        right: 1rem;
        width: 8.4rem;
        height: 100%;
        background: url('../images/template12/icon15_1.png') no-repeat;
        background-size: cover;
        z-index: 0;
        @media (max-width:1024px) {
            right: 0;
        }
    }
    .bjBox{
        position: absolute;
        right: 0rem;
        bottom: 1.2rem;
        width: 11rem;
        height: 3.6rem;
        background: rgba(45,216,232,0.48);
        backdrop-filter: blur(3px);
    }
    .top-left{
        .appName{
            color: #fff !important;
        }
    }
    .cont-box{
        padding-top: 2.5rem;
        position: relative;
        z-index: 1;
        .appName{
            color: #fff;
            margin-bottom: 0.1rem;
        }
    }
    .links{
        position: initial;
        display: flex;
        margin-right: 1rem;
        z-index: 3;
        @media(max-width:700px){
            margin-right: 0;
            margin-top:.3rem;
        }
        .newLine{
            width: 0.03rem;
            background: #929292;
            border-radius: 0.02rem;
            margin-left: 0.5rem;
        }
        a{
            margin:0 0 0 0.5rem;
            font-size: .24rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #929292;
            line-height: .33rem;
            position:relative;
            transition:color 0.3s ease;
            @media(max-width:1024px){
                margin:0 .2rem;
                color: #000;
            }

            &:hover{
                color:#636363;

            }

        }
    }
}
.template15-pact{
    width: 100%;
    height: 100vh;
    background: #D7DFE2;
    overflow: hidden;
    position: relative;
    .bg{
        position: absolute;
        right: 0;
        height: 100%;
        width: 5.12rem;
        background: url('../images/template12/icon15_3.png') no-repeat;

    }
    .bg2{
        position: absolute;
        right: 0;
        height: 100%;
        width: 8rem;
        background: linear-gradient(90deg, rgba(215,223,226,0) 0%, rgba(215,223,226,0.72) 100%);
        backdrop-filter: blur(6px);
    }
    .right-content{
        // width: 100%;
        height: 90%;
        position: relative;
        margin: 2.4rem 1.2rem 0;
        background: rgba(255,255,255,0.2);
        backdrop-filter: blur(12px);
        // overflow: hidden;
        @media (max-width:1024px) {
            margin: 0;
            height: 100%;
        }
        .pact-cont{
            // height: 100vh;
            padding: 0.4rem;
            padding-bottom: 2.2rem;
        }
        .bg3{
            width: 6.52rem;
            height: 1.65rem;
            background: rgba(45,216,232,0.48);
            backdrop-filter: blur(3px);
            position: absolute;
            right: -1.2rem;
            top: -1.65rem;
            font-size: 0.8rem;
            text-align: center;
            line-height: 1.65rem;
            color: #fff;
            @media (max-width:1024px) {
                top: 0;
                width: 100%;
                position: initial;
            }
        }
        .bg4{
            width: 1.2rem;
            position: absolute;
            right: -1.2rem;
            height: 100%;
            background: rgba(198,250,255,0.73);
            backdrop-filter: blur(3px);
            // z-index: 4;
        }
    }

}
.template16{
    min-height:100vh;
    // height: 100vh;
    overflow: hidden;
    position:relative;
    background: url('../images/template16/homebg.png');
    // background-position: center;
    background-size:auto;
    background-repeat: no-repeat;
    @media (max-width:760px) {
        background: url('../images/template16/homebg1.png')  no-repeat;
        background-size: contain;
    }
    .tab{
        width: 0.56rem;
        height: 0.56rem;
        background: url('../images/template16/icon_2.png')  no-repeat;
        background-size: contain;
        position: absolute;
        top: 0.44rem;
        right: 1rem;
        z-index: 2;
        cursor: pointer;
    }
    .tab1{
        background: url('../images/template16/icon_1.png')  no-repeat;
        background-size: contain;
    }
    .linkBiox{
        position: absolute;
        right: 1rem;
        top: 1.2rem;
        width: 3rem;
        height: 2.7rem;
        background: rgba(0,0,0,0.8);
        border-radius: 0.14rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1;
        z-index: 4;
        a{
            height: 33%;
            display: flex;
            align-items: center;
            padding-left: 0.3rem;
            color: #fff;
            font-size: 0.24rem;
            font-weight: bold;
            opacity: 0.8;
            &:hover{
                opacity: 1;
            }
        }
    }
    .bjTop{
        width: 3.12rem;
        height: 1.58rem;
        background: url('../images/template16/icon_5.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width:760px) {
            width: 1.56rem;
            height: 0.8rem;
            background: url('../images/template16/icon_7.png')  no-repeat;
            background-size: contain;
        }
    }
    .bjBox{
       width: 11rem;
       height: 2.64rem;
       background: url('../images/template16/icon_6.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        right: 0;
        @media (max-width:760px) {
            width: 5.5rem;
            height: 1.32rem;
            background: url('../images/template16/icon_8.png')  no-repeat;
            background-size: contain;
        }
    }
    .cont-box{
        max-width: 10rem;

        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-top: 1.2rem;
        margin-right: 1.4rem;
        // padding-bottom: 1rem;
        @media (max-width:760px) {
            margin: 0;
           margin-top: 1rem;
           padding: 0.3rem;
        }
        .appName{
            font-size: 2rem;
            font-family: Condiment, Condiment;
            font-weight: 400;
            color: #15100C;
            line-height: 3rem;
            text-decoration: underline;
            @media (max-width:760px) {
                font-size: 1rem;
                line-height: 1.5rem;
                margin-bottom: 0.4rem;
             }
        }
        .privacy16{
            font-size: 1.2rem;
            color: #15100C;
            text-decoration: underline;
            margin-top: 0.6rem;
            @media (max-width:760px) {
                font-size: 1rem;
                margin-top: 0;
            }
        }
        .cont{
            font-size: 0.28rem;
            font-family: LibreBaskerville, LibreBaskerville;
            font-weight: bold;
            color: #333333;
            height: 100%;
            padding-bottom: 0.2rem;
        }
        .download{
            display: flex;
            @media (max-width:760px) {
                flex-direction: column-reverse;
                position: absolute;
                bottom: 1rem;
                left: 50%;
                transform: translate(-50%);
                .download-item{
                    margin-top: 0.4rem;
                }
            }
            img{
                margin-right: 0.4rem;
                width: 3.5rem;
            }
        }
    }
}
.privacy16Box{
    @media (max-width:760px) {
        background: url('../images/template16/homebg2.png')  no-repeat;
        background-size: contain;
    }
    .cont-box{
        height: calc(100vh - 1.58rem);
    }
}
.template17{
    min-height:100vh;
    // height: 100vh;
    overflow: hidden;
    position:relative;
    // background-color: #358DDB;
    background:linear-gradient(to bottom,#358DDB 18%,#a3cbed 0,#a3cbed 44%,#358DDB 0);
    background-size: 100% 100%;
    padding: 0.6rem 0;
    .contBox{
        display: flex;
        justify-content: center;
        height: 100%;
        .box{
            background: #fff;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .box1{
            width: 6rem;
            height: 8.6rem;

            .book_img{
                width: 2.32rem;
                height: 3.1rem;
                margin: 0 auto;
            }
            .appName{
                font-size: 0.4rem;
                font-family:"Roboto-Bold";
                color: #333333;
                margin: 0.1rem 0 0.3rem 0;
            }
            .book_test{
                width: 5.32rem;
                height: 3.6rem;
                background: #EBF5FF;
                border: 2px solid #358DDB;
                padding: 0.2rem 0.26rem;
                text-align: center;
                font-size: 0.28rem;
                font-family: PingFangSC, PingFang SC;
                font-weight: 600;
                color: #358DDB;
                line-height: 0.4rem;
            }
        }
        .box2{
            width: 4rem;
            height: 6.6rem;
            margin: 0 0.8rem;
            align-self: flex-end;
            .getPlay{
                width: 2.4rem;
                font-size: 0.4rem;
                font-family:"Roboto-Bold";
                color: #000;
                text-align: center;
                line-height: 0.44rem;
            }
            .iconBottom{
                width: 0.6rem;
                height: 0.6rem;
                margin: 0.2rem 0;
                background-size: contain;

            }
            .down_img{
                width: 3rem;
                height: 3rem;
                cursor: pointer;
            }
        }
        .box3{
            width: 4rem;
            height: 6.6rem;
            background: #fff;
            flex-shrink: 0;
            a{
                &:nth-child(2){
                    margin: 0.2rem 0;
                }
            }
            .boxIcon{
                width: 3.32rem;
                height: 1.56rem;
                box-sizing: border-box;
                background: #EBF5FF;
                border: 2px solid #358DDB;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0.2rem;
                font-size: 0.4rem;
                font-family:"Roboto-Bold";
                color: #358DDB;
                line-height: 0.44rem;
                // .newIcon{
                //     width: 2rem;
                // }
                .iconRight{
                    width: 0.6rem;
                    height: 0.6rem;
                    margin: 0.2rem 0;
                    flex-shrink: 0;
                }
            }
        }
    }
}
.template17{
    @media (max-width:800px) {
        background:linear-gradient(to bottom,#358DDB 10%,#a3cbed 0,#a3cbed 26%,#358DDB 0);
        padding: 0.6rem 0.55rem;
        .contBox{
            flex-direction: column;
            .box{
                width: 100%;
            }
            .box2{
                height: 5.76rem;
                margin: 0.28rem 0;
                .getPlay{
                    width: 100%;
                }
            }
            .box3{
                height: 4.28rem;
                .boxIcon{
                    width: 5.72rem;
                    height: 1.08rem;
                    .iconRight{
                        margin: 0;
                        background-size: contain;
                    }
                }
            }
        }
    }
}
.template17-page{
    height: 100vh;
    overflow: hidden;
    position:relative;
    // background-color: #358DDB;
    background:linear-gradient(to bottom,#358DDB 18%,#a3cbed 0,#a3cbed 46%,#358DDB 0);
    background-size: 100% 100%;
    padding: 0.6rem 1.6rem;
    @media screen {

    }
    .bigBox{
        width: 100%;
        height: 100%;
        background: #fff;
        padding:0.6rem 0.4rem 0.6rem 1.2rem;
        display: flex;

        .title{
            font-size: 0.6rem;
            line-height: 0.7rem;
            font-weight: bold;
            margin-bottom: 0.3rem;
            // font-family:"Roboto-Bold";
        }
        .contBox{
            width: 100%;
            height: 100%;
        }
        .cont{
            height: calc(100% - 0.6rem);
            p{
                font-size: 0.16rem;
                color: #333;
                margin-bottom: 0.32rem;
            }
            .subTitle{
                font-size: 0.24rem;
                line-height: 0.28rem;
                font-weight: bold;
                color: #358DDB;
                margin-bottom: 0.12rem;
            }
        }
        .boxIcon{
            width: 3.32rem;
            height: 1.56rem;
            box-sizing: border-box;
            background: #EBF5FF;
            border: 2px solid #358DDB;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 0.2rem;
            font-size: 0.4rem;
            font-family:"Roboto-Bold";
            color: #358DDB;
            line-height: 0.44rem;
            margin-bottom: 0.2rem;
            margin-left: 0.4rem;
            // .newIcon{
            //     width: 2rem;
            // }
            .iconRight{
                width: 0.6rem;
                height: 0.6rem;
                margin: 0.2rem 0;
                flex-shrink: 0;
            }
        }
    }
}
.template17-page{
    @media (max-width:1024px) {
        padding: 0.8rem 0.55rem;
        .bigBox{
            width: 100%;
            flex-direction: column;
            background: none;
            padding: 0;
            .contBox{
                // height: calc(100% - 4.6rem);
                background: #fff;
                padding: 0.46rem 0.32rem;
            }
            .box{
                width: 100%;
                background: #fff;
                padding: 0.32rem;
                margin-top: 0.34rem;
                .boxIcon{
                    width: 100%;
                    margin-left: 0;

                    .iconRight{
                        background-size: contain;
                    }
                }
                .mb4{
                    margin-bottom: 0;
                }
            }
            .cont{
                p{
                    font-size: 0.28rem;
                    line-height: 0.36rem;
                    margin-bottom: 0.4rem;
                }
                .subTitle{
                    font-size: 0.28rem;
                    margin-bottom: 0.2rem;
                }
            }
        }
    }
}
.template18{
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: url('../images//template16/temp18_1.png') no-repeat;
    background-size: cover;
    .contBox{
        width: 13.32rem;
        height: 9.32rem;
        background: url('../images//template16/temp18_4.png') no-repeat;
        background-size: 100%;
        margin-left: auto;
        // position: absolute;
        // right: 0;
        // top: 0;

    }
    .contTitle{
        width: 6.39rem;
        height: 4.4rem;
        position: absolute;
        top: 2rem;
        left: 1.2rem;
        background: url('../images//template16/temp18_5.png') no-repeat;
        background-size: 100%;
    }
    .down{
        width: 3.3rem;
        height: 1.06rem;
        background: url('../images//template16/temp18_7.png') no-repeat;
        background-size: 100%;
        position: absolute;
        top: 7rem;
        left: 1.2rem;
    }
    .links{
        position: absolute;
        right: 0;
        bottom: 0.4rem;
        margin-top: 1rem;
        a{
            font-size: 0.24rem;
            color: #7C6737;
            &:hover{
                color: #222;
            }
            margin-right: 1rem;
        }
    }
}
.template18{
    @media (max-width:800px) {
        .contBox{
            width: 100%;
            height: 5.25rem;
            background: url('../images//template16/temp18_3.png') no-repeat;
            background-size: contain;
        }
        .contTitle{
            position: initial;
            width: 6.39rem;
            height: 4.4rem;
            margin: 0.2rem auto;
        }
        .down{
            position: initial;
            margin-left: 0.56rem;
            margin-top: 0.5rem;
        }
        .links{
            display: flex;
            justify-content: space-around;
            width: 100%;
            a{
               margin: 0;
            }
        }
    }
}
.template18-page{
    height: 100vh;
    .col7c6{
        color: #7C6737;
        font-size: 0.30rem;
        font-family: "Roboto-Bold";
    }
    p{
        margin-bottom: 0.2rem;
    }
    .bigBox{
        height: 100%;
        display: flex;
        .box{
            width: 30%;
            border-right:1px solid #7c6737 ;
            .boxLink{
                margin-top: 1rem;
                margin-left: auto;
                .boxIcon{
                    width: 2.6rem;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    margin-left: auto;
                    padding-left: 0.2rem;
                    color: #7C6737;

                }
                .active{
                    background: linear-gradient(270deg, #FFFFFF 0%, #E6D9BE 100%);
                }
            }
        }
        .contBox{
            width: 70%;
            margin-top: 1rem;
            padding-left: 0.65rem;
            margin-right: 3.2rem;
            .title{
                font-size: 0.4rem;
                font-weight: bold;
                color: #7C6737;
            }
            .newLine{
                width: 100%;
                height: 0.01rem;
                background: #7C6737;
                margin: 0.2rem 0;
            }
            .cont{
                height: calc(100% - 2rem);
            }
        }
    }
}
.template18-page{
    @media (max-width:1024px) {
        .bigBox{
            .box{
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 1rem;
                line-height: 1rem;
                border-top:1px solid #7c6737 ;
                border-right: none;
                .boxLink{
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    margin: 0;
                    .boxIcon{
                        width: 100%;
                        height: 1rem;
                        line-height: 1rem;
                    }
                    .activeBox{
                        display: none;
                    }
                }
            }
            .contBox{
                width: 100%;
                margin: 0;
                padding:  0.2rem;

            }
        }

    }
}
.template19{
    min-height: 100vh;
    position: relative;
    overflow: auto;
    background: url('../images/template19/icon19_1.png') no-repeat;
    background-size: cover;
    .contBox{
        width: 100%;
        height: 8.18rem;
        position: absolute;
        left: 0;
        bottom: 0;
        margin-bottom: auto;
        background: url('../images/template19/icon19_7.png') no-repeat;
        background-size: cover;
        background-position: right bottom;
    }
    .contPeo{
        width: 100%;
        height: 8.28rem;
        max-height:88%;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url('../images/template19/icon19_2.png') no-repeat;
        background-size: contain;
        background-position: left bottom;
    }
    .conttext{
        width: 2.93rem;
        height: 2.75rem;
        position: absolute;
        left: 50%;
        bottom: 30%;
        transform: translate(-50% -50%);
        background: url('../images/template19/icon19_4.png') no-repeat;
        background-size: contain;
        z-index: 2;
        // background-position: left bottom;
    }
    .logo{
        display: flex;
        align-items: center;
        position: absolute;
        top: 0.6rem;
        left: 0.68rem;
        .logoImg{
            width: 0.88rem;
            height: 0.59rem;
        }
        .appName{
            margin-left: 0.2rem;
            color: #fff;
            font-size: 0.30rem;
            font-family: "Roboto-Bold";;
        }
    }
    .contWo{
        width: 6.4rem;
        height: 70%;
        position: absolute;
        left: 26%;
        bottom: 0;
        // transform: translate(-50% -50%);
        background: url('../images/template19/icon19_3.png') no-repeat;
        background-size: contain;
        background-position: left bottom;
    }
    .linkBox{
        width: 0.6rem;
        height: 0.6rem;
        background: url('../images/template19/icon19_5.png') no-repeat;
        background-size: contain;
        position: absolute;
        top: 0.6rem;
        right: 0.68rem;
        cursor: pointer;
    }
    .links{
        width: 4rem;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 1.68rem;
        a{
            width: 2.4rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            color: #FA00A7;
        }
        .active{
            background: #FA00A7;
            border-radius: 0.30rem;
            color: #fff;
            font-family: "Roboto-Bold";
        }
        .closeIcon{
            width: 0.32rem;
            height: 0.28rem;
            background: url('../images/template19/icon19_8.png') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0.6rem;
            left: 0.68rem;
            cursor: pointer;
        }
    }
    .down{
        width: 2.64rem;
        height: 0.8rem;
        position: absolute;
        right: 1.64rem;
        top: 0.5rem;
        background: url('../images/template19/icon19_6.png') no-repeat;
        background-size: contain;
        background-position: left bottom;
    }
}
.template19{
    @media (max-width:1044px) {
        .contWo{
            display: none;
        }
        .down{
            width: 3.96rem;
            height: 1.2rem;
            bottom: 1rem;
            top: initial;
            left: 50%;
            transform: translate(-50%);
        }
        .conttext{
            width: 3.74rem;
            height: 3.49rem;
            background: url('../images/template19/icon19_4.png') no-repeat;
            background-size: contain;
            top: 3rem;
            left: 0.5rem;
        }
    }
}
.template19-page{
    height: 100%;
    min-height: 100vh;
    background: #FFEBFC;
    .bigBox{
        display: flex;
    }
    .contBox{
        flex: 1 ;
        .nexBox{
            width: 60%;
            margin: 1rem auto 1rem 1.2rem;
            .title{
                font-size: 0.4rem;
                font-weight: bold;
                color: #000;
                line-height: 0.47rem;
                padding-bottom: 0.2rem;
                border-bottom: 1px solid #000;
            }
            .cont{
                height: calc(100vh - 2.8rem);
                padding-top: 0.3rem;
                p{
                    margin-bottom: 0.24rem;
                }
                .subTitle{
                    font-size: 0.22rem;
                    color: #FA00A7;
                    line-height: 0.28rem;
                    margin-bottom: 0.1rem;
                }
            }
        }
    }
    .links{
        width: 4rem;
        height: 100vh;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;

        padding-top: 1.68rem;
        .closeIcon{
            display: none;
        }
        a{
            width: 2.4rem;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            color: #FA00A7;
        }
        .active{
            background: #FA00A7;
            border-radius: 0.30rem;
            color: #fff;
            font-family: "Roboto-Bold";;
        }
        .closeIcon{
            width: 0.32rem;
            height: 0.28rem;
            background: url('../images/template19/icon19_8.png') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0.6rem;
            left: 0.68rem;
            cursor: pointer;
        }
    }
}
.template19-page{
    @media (max-width:1020px) {
        p{
            color: #222;
        }
        .links{
            position: absolute;
            right: 0;
            top: 0;
            .closeIcon{
                display: block;
            }
        }
        .contBox{
            .nexBox{
                width: 100%;
                padding: 0 0.4rem;
                margin: 0;
                margin-top: 0.5rem;
                position: relative;
                .cont{
                    height: calc(100vh - 1.8rem);
                    padding-top: 0.3rem;
                    p{
                        margin-bottom: 0.4rem;
                    }
                    .subTitle{
                        font-size: 0.32rem;
                        margin-bottom: 0.16rem;
                    }
                }
                .title{
                    padding-bottom: 0.4rem;
                }
                .newBtn{
                    width: 0.7rem;
                    height: 0.7rem;
                    background: url('../images/template19/icon19_5.png') no-repeat;
                    background-size: contain;
                    position: absolute;
                    right: 0.4rem;
                    top: -0.1rem;
                }
            }
        }
    }
}
.template20{
    min-height: 100vh;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url('../images/template19/icon20_1.png') no-repeat;
    background-size: cover;
    .contBox{
       width: 7.2rem;
       height: 80%;
       background: rgba(40,116,98,0.2);
       border-radius: 0.40rem;
       border: 1px solid #2BFFB3;
       backdrop-filter: blur(0.2rem);
       margin-top: 6%;
       margin-left: auto;
       margin-right: 1.6rem;
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-bottom: 0.3rem;
       padding-bottom: 0.5rem;
       .tales{
           width: 2.6rem;
           height: 2.6rem;
           background: url('../images/FantasyLand.png') no-repeat;
           background-size: contain;
           margin-top: 0.45rem;
           margin-bottom: 0.42rem;
       }
       .title{
           text-align: center;
           font-size: 0.28rem;
           color: #fff;
           line-height: 0.32rem;
           margin-bottom: 0.48rem;
       }
       .down{
           width: 3.96rem;
           height: 1.2rem;
        //   background: url('../images/template19/icon20_3.png') no-repeat; @/assets/images//template16/icon_4.png
            background:url("../images/template16/icon_4.png") no-repeat;
           background-size: contain;
           margin-bottom: 0.6rem;
       }
       .links{
           display: flex;
           justify-content: center;
           .m42{
               margin: 0 0.42rem;
           }
           a{
               font-size: 0.16rem;
               color: #fff;
               font-family: Cochin;
            //
               opacity: 0.7;
               &:hover{
                   opacity: 1;
               }
           }
       }
    }
}

.template20-page{
    min-height: 100vh;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: url('../images/template19/icon20_1.png') no-repeat;
    .contBox{
        // display: inline;
        // padding-bottom: 0.2rem;
        width: 56%;
        padding: 0.4rem;
        .title{
            width: 100%;
            text-align: left;
            font-size: 0.4rem;
            color: #2BFFB3;
            // padding: 0.4rem;
            border-bottom: 1px solid #2BFFB3;
            padding-bottom: 0.2rem;
        }
        .cont{
            width: 100%;
            height: calc(100vh - 3.3rem);
            color: #fff;
            p{
                opacity: 0.8;
                margin-bottom: 0.32rem;
                line-height: 0.28rem;
            }
            .subTitle{
                font-size: 0.24rem;
                color: #fff;
                opacity: 1;
                margin-bottom: 0.12rem;
            }
            // padding:0 0.4rem;
            // .pact-cont{
            //     height: 100%;
            // }
        }

    }
}
.template20{
    @media (max-width:820px) {
        background: url('../images/template19/icon20_4.png') no-repeat;
        background-size: cover;
        .contBox{
            width: 100%;
            border: none;
            backdrop-filter: none;
            background: none;
            margin: 0;
            height: 100%;
            .tales{
                margin-top: 1.2rem;
                width: 3rem;
                height: 3rem;
            }

            .links{
                position: absolute;
                bottom: 0.4rem;
                a{
                    font-size: 0.24rem;
                    color: #fff;
                    opacity: 1;
                }
                .m42{
                    margin: 0 0.6rem;
                }
            }
        }
    }
}
.template20-page{
    @media (max-width:800px) {
        padding: 0.32rem;
        .contBox{
            background: rgba(40,116,98,0.2);
            border-radius: 0.40rem;
            border: 1px solid #2BFFB3;
            backdrop-filter: blur(40px);
            // margin: 0.4rem;
            .cont{

                height: calc(100vh - 2.6rem);
                p{
                    opacity: 0.8;
                    margin-bottom: 0.4rem;
                    line-height: 0.4rem;
                }
                .subTitle{
                    font-size: 0.32rem;
                    line-height: 0.48rem;
                    color: #fff;
                    opacity: 1;
                    margin-bottom: 0.12rem;
                }
            }
        }
    }

}
.template21{
    height: 100%;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: url('../images/template19/icon21_1.png') no-repeat;
    .content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        .logo{
            width: 1.6rem;
            height: 1.6rem;
            margin-top: 2.3rem;
            background: url('../images/template19/icon21_5.jpeg') no-repeat;
            background-size: contain;
            border-radius: 0.36rem;
            border: 0.04rem solid #fff;
        }
        .appName{
            font-size: 0.56rem;
            color: #fff;
            font-family: 'Poppins', sans-serif;
            margin-top: 0.1rem;
        }
        .appName2{
            font-size: 0.3rem;
            font-family: 'Poppins', sans-serif;
            color: #fff;
        }
    }
    .foot{
        width: 100%;
        // height: 3.2rem;
        position: fixed;
        bottom: 0;
        background: #fff;
        .text{
            width: 60%;
            text-align: center;
            margin: 0.52rem auto;
            font-family: 'Poppins', sans-serif;
            p{
                margin-bottom: 0;
            }
        }
        .download,.links{
            display: none;
        }
        .hoverDown{
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.2rem;
            .down,.menu{
                width: 2.8rem;
                height: 0.72rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 1.8rem;
                border: 1px solid #000;
                font-size: 0.3rem;
                cursor: pointer;
                position: relative;
                &:hover{
                    background: #000;
                    color: #fff;
                    .download{
                        display: block;
                    }
                }

            }

            .down{
                .download{
                    position: absolute;
                    top: -1.2rem;
                    left: 50%;
                    transform: translate(-50%);
                    width: 3.5rem;
                    height: 1.06rem;
                    display: none;
                    // &:hover{
                    //     display: block;
                    // }
                }
            }
            .newbox{
                height: 100%;
                padding: 0.2rem 0;
                &:hover{
                    .menu{
                        background: #000;
                        color: #fff;
                    }
                    .hoverBox{
                        display: block;
                    }
                }
            }
            .menu{
                margin-right: 0;
                .hoverBox{
                    display: none;
                    position: absolute;
                    top: -2.2rem;
                    width: 100%;
                    margin-bottom: 0.3rem;
                }
                .links{
                    // display: none;
                    // position: absolute;
                    // top: -2.1rem;
                    display: block;
                    left:0;
                    width: 100%;
                    // display: flex;
                    text-align: center;
                    background: rgba(0,0,0,0.9);
                    border-radius: 0.08rem;
                    a{
                        color: #fff;
                        font-size: 0.24rem;
                        display: block;
                        padding-top: 0.24rem;
                        opacity: 0.8;
                        &:last-child{
                            padding-bottom: 0.24rem;
                        }
                        &:hover{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
.template21{
    @media (max-width:800px) {
        background: none;
        .top{
            width: 100%;
            height: 7.2rem;
            background: url('../images/template19/icon21_2.png') no-repeat;
            background-size: contain;
            // position: absolute;
            // z-index: -1;
        }
        .content{
            top: 0;
            .logo{
                margin-top: 2.6rem;
            }
            .appName2{
                width: 70%;
                text-align: center;
                opacity: 0.7;
            }
        }
        .foot{
            position: relative;
            .text{
                width: 100%;
                padding: 0.40rem 0.66rem 0.3rem;
                margin: 0 auto;
                font-size: 0.32rem;
            }
            .hoverDown{
                display: none;
            }
            .download{
                display: block;
                width: 3.5rem;
                height: 1.06rem;
                margin: 0 auto;
            }
            .links{
                display: flex;
                width: 100%;
                justify-content: center;
                align-items: center;
                margin-top: 0.1rem;
                a{
                    font-size: 0.24rem;
                    color: rgba(0,0,0,0.5);
                    margin: 0.22rem 0 0.3rem;
                    &:nth-child(2){
                        margin: 0.22rem 0.4rem 0.3rem;
                    }
                }
            }
        }
    }
}
.template21-page{
    height: 100vh;
    position: relative;
    overflow: hidden;
    .top{
        position: absolute;
        top: 0;
        height: 1.6rem;
        width: 100%;
        background: url('../images/template19/icon21_3.png') no-repeat;
        z-index:-1;
    }
    .contBox{
        width: 60%;
        margin: 0 auto;
        @media (max-width:800px) {
            width: 100%;
            padding: 0 0.4rem ;
        }
        // position: absolute;
        .title{
            height: 1.6rem;
            line-height: 1.6rem;
            font-size: 0.6rem;
            color: #fff;
        }
        .cont{
            height: calc(100vh - 1.8rem);
            padding: 0.3rem 0;
            p{
                margin-bottom: 0.24rem;
                padding-bottom: 0;
                font-size: 0.24rem;
                color: #373737;
            }
            .subTitle{
                font-size: 0.28rem;
                color: #373737;
                line-height: 0.28rem;
                font-weight: bold;
                margin-bottom: 0.2rem;
            }
        }
    }
}
.template22{
    background: url('../images/template19/icon22_1.png') no-repeat;
    background-position-x: center;
    background-size: cover;
    font-family: 'Josefin Slab', serif;
    overflow: auto;
    .content{
        width: 6.84rem;
        margin: 2rem auto 0;
        position: relative;
        .about,.down{
            width: 100%;
            height: 1.8rem;
            background: #EC46BF;
            border-radius: 0.4rem;
            border: 0.06rem solid #FFFFFF;
            text-align: center;
            line-height: 1.8rem;
            font-size: 0.5rem;
            color: #fff;
            cursor: pointer;
        }
        .down{
            background: #C046EC;
            margin-top: 0.24rem;
        }
        .aboutApp{
            position: absolute;
            width: 100%;
            top: -0.1rem;
            background: #EC46BF;
            border-radius: 0.4rem;
            border: 0.06rem solid #FFFFFF;
            text-align: center;
            padding: 0 0.82rem 0.3rem;
            z-index: 2;
            .title{
                font-size: 0.5rem;
                color: #fff;
                margin: 0.56rem 0 0.4rem;

            }
            .text{
                font-size: 0.24rem;
                color: #fff;
                margin-bottom: 0.2rem;
            }
            .close{
                position: absolute;
                right: 0.4rem;
                top: 0.4rem;
                width: 0.2rem;
                height: 0.2rem;
                background: url('../images/template19/icon22_5.png') no-repeat;
                background-size: contain;
            }
        }
        .downNow{
            background: #C046EC;
            min-height: 4rem;
            .download{
                width: 4rem;
                margin: 0 auto;
                .download-item{
                    margin-bottom: 0.3rem;
                }
            }
        }
    }
    .contentMoble{
        display: none;
    }
    .foot{
        position: fixed;
        bottom: 0.4rem;
        width: 100%;
        font-family: 'Roboto';
        .footBox{
            max-width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            @media (max-width:1200px) {
                flex-direction: column;
            }
            .copy{
                color: #fff;
                opacity: 0.5;
                font-size: 0.14rem;
                margin-right: 0.2rem;
            }
            .links{
                a{
                    padding:0 0.2rem;
                    border-left: 0.01rem solid rgba(255,255,255,0.2);
                    color: rgba(255,255,255,0.5);
                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.template22{
    @media (max-width:800px) {
        .content{
            display: none;
        }
        .contentMoble{
            display: block;
            margin-top: 1rem;
            .aboutApp{
                position: inherit;
                margin-bottom: 0.32rem;
                padding: 0 0.42rem 0.3rem ;
                .text{
                    font-size: 0.35rem;
                }
            }
            .download-item{
                margin-bottom: 0.48rem !important;
            }
        }
        .foot{
            position: inherit;
            margin-top: 2rem;

            .footBox{
                max-width: 100%;
                .copy{
                    font-size: 0.2rem;
                    margin-left: 0;
                    margin-bottom: 0.2rem;
                }
                .links{
                    a{
                        border: none;
                        &:nth-child(2){
                            border-left: 0.01rem solid rgba(255,255,255,0.2);
                            border-right: 0.01rem solid rgba(255,255,255,0.2);
                        }
                    }
                }
            }
        }
    }
}
.template22-page{
    background: #1A0A47;
    font-family: 'Josefin Slab', serif;
    .contBox{
        width: 60%;
        margin: 0 auto;
        .title{
            font-size: 0.4rem;
            margin-top: 0.7rem;
            line-height: 0.4rem;
            padding-bottom: 0.2rem;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            color: #fff;
            font-weight: bold;
        }
        .cont{
            height: calc(100vh - 2.4rem);
            padding: 0.24rem 0;
            color:rgba(255,255,255,0.8);
            overflow: auto;

            p{
                line-height: 0.24rem;
                margin-bottom: 0.24rem;
                font-size: 0.16rem;
            }
            .subTitle{
                color: #EC46BF;
                font-size: 0.22rem;
                margin-bottom: 0.1rem;
                font-weight: bold;
                // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
    }
    .foot{
        position: fixed;
        bottom: 0;
        width: 100%;
        background: #000;
        padding: 0.3rem 0;
        font-family: Roboto;
        .footBox{
            // max-width: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            @media (max-width:1200px) {
                flex-direction: column;
            }
            .copy{
                color: #fff;
                opacity: 0.5;
                font-size: 0.14rem;
                margin-right: 0.2rem;
            }
            .links{
                a{
                    padding:0 0.2rem;
                    border-left: 0.01rem solid rgba(255,255,255,0.2);
                    color: rgba(255,255,255,0.5);
                    &:hover{
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.template22-page{
    @media (max-width:800px) {
        .contBox{
            width: 100%;
            padding: 0 0.4rem;
            .cont{
                p{
                    line-height: 0.4rem;
                    margin-bottom: 0.4rem;
                    font-size: 0.26rem;
                }
                .subTitle{
                    color: #EC46BF;
                    font-size: 0.32rem;
                    margin-bottom: 0.1rem;
                    font-weight: bold;
                    // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                }
            }
        }

        .foot{
            position: fixed;

            // margin-top: 2rem;
            .footBox{
                max-width: 100%;
                .copy{
                    font-size: 0.2rem;
                    margin-left: 0;
                    margin-bottom: 0.2rem;
                }
                .links{
                    a{
                        border: none;
                        &:nth-child(2){
                            border-left: 0.01rem solid rgba(255,255,255,0.2);
                            border-right: 0.01rem solid rgba(255,255,255,0.2);
                        }
                    }
                }
            }
        }
    }
}
.template23{
    background: url('../images/template19/icon23_1.png'),url('../images/template19/icon23_2.png') no-repeat;
    background-position: center;
    font-family: 'Pangolin', cursive;
    color: #543005;
    .absolute{
        position: absolute;
    }
    .top{
        top: 0;
        left: 0;
        width: 4.36rem;
        height: 2rem;
        background: url('../images/template19/icon23_3.png') no-repeat;
        background-size: contain;
    }
    .right{
        top: 0;
        right: 0;
        width: 4.64rem;
        height: 2.86rem;
        background: url('../images/template19/icon23_4.png') no-repeat;
        background-size: contain;
        .contact{
            width: 1.8rem;
            height: 0.34rem;
            line-height: 0.34rem;
            text-align: center;
            color: #543005;
            position: absolute;
            right: 0.6rem;
            top: 0.46rem;
            font-size: 0.24rem;
        }
    }
    .bottom{
        bottom: 0;
        right: 0;
        width: 6.62rem;
        height: 2.62rem;
        background: url('../images/template19/icon23_5.png') no-repeat;
        background-size: contain;
    }
    .left{
        bottom: 0;
        left: 0;
        width: 2.54rem;
        height: 4.2rem;
        background: url('../images/template19/icon23_6.png') no-repeat;
        background-size: contain;
        .toLink{
            display: flex;
            flex-direction: column;
            transform: rotate(-10deg);
            position: absolute;
            bottom: 0.7rem;
            left: 0.14rem;
            a{
                color: #543005;
                font-size: 0.2rem;
                margin-bottom: 0.1rem;
            }
        }
    }
    .content{
        width: 10.8rem;
        height: 6.08rem;
        position: absolute;
        left: 50%;
        top: 46%;
        transform: translate(-50%, -50%);
        background: url('../images/template19/icon23_7.png') no-repeat;
        background-size: contain;
        display: flex;
        align-items: center;
        justify-content: center;
        .down{
            width: 3.85rem;
            height: 2.32rem;
            position: absolute;
            bottom: -0.5rem;
            right: 1.5rem;
            cursor: pointer;
        }
        .appInfo{
            .appLogo{
                width: 1.4rem;
                height: 1.4rem;
                background: #ccc;
            }
            .appname{
                font-size: 0.3rem;
                color: #543005;
                text-align: center;
            }
        }
        .appText{
            width: 54%;
            padding-left: 0.48rem;
            margin-left: 0.7rem;
            border-left: 1px solid rgba(150, 106, 51, 0.4);

            .title{
                font-size: 0.34rem;
                color: #543005;
                line-height: 0.43rem;
                padding-bottom: 0.24rem;
                border-bottom: 1px solid rgba(150, 106, 51, 0.4);
                margin-bottom: 0.3rem;
            }
            .text{
                font-size: 0.26rem;
                color: #543005;
                line-height: 0.32rem;
                div{
                    margin-bottom: 0.2rem;
                }
            }
        }
    }

}
.template23{
    @media (max-width:900px) {
        background: url('../images/template19/icon23_1.png'),url('../images/template19/icon23_2.png') no-repeat;
        .content{
            width: 6.7rem;
            height: 56%;
            margin: 0 auto;
            background: #F3EBCD;
            box-shadow: 0.02rem 0.04rem 0.06rem 0px rgba(0,0,0,0.2);
            flex-direction: column;
            justify-content: flex-start;
            top: 6.6rem;
            .appInfo{
                margin-top: 1rem;
                .appLogo{
                    width: 2.4rem;
                    height: 2.4rem;
                }
                .appname{
                    font-size: 0.6rem;
                    margin-top: 0.32rem;
                    margin-bottom: 0.22rem;
                }
            }
            .appText{
                border: none;
                width: 100%;
                padding: 0;
                margin: 0;
                text-align: center;
                .title{
                    border: none;
                }
                .text{
                    display: none;
                }
            }
            .down{
                right: 0.5rem;
                bottom: -1.2rem;
            }
        }
    }
}
.template23-page{
    height: 100vh;
    .contBox{
        position: relative;
        z-index: 2;
        width: 62%;
        background: #F3EBCD;
        box-shadow: 2px 4px 6px 0px rgba(0,0,0,0.2);
        margin: 0 auto;
        height: calc(100vh - 1.2rem);
        overflow: hidden;
        margin-top: 0.6rem;
        padding: 0.3rem 0.7rem 0.6rem;
        .title{
            font-size: 0.7rem;
            transform: rotate(-5deg);
            margin-bottom: 0.2rem;
        }
        .cont{
            height: calc(100% - 1.4rem);
            box-sizing: border-box;
            overflow: auto;
            p{
                margin-bottom: 0.32rem;
                &:last-child{
                    padding-bottom: 0.5rem;
                }
            }
            .subTitle{
                font-size: 0.3rem;
                margin-bottom: 0.2rem;
            }
        }
    }
}
.template23-page{
    @media (max-width:800px) {
        .contBox{
            width: 6.7rem;
            margin: 2.0rem auto 0.6rem;
            padding:0 0.34rem;
            height: calc(100vh - 2.4rem);
            z-index: 4;
        }
    }
}
.template24{
    background: url('../images/template24/icon_1.png') no-repeat;
    background-position-x: center;
    background-size: cover;
    .content{
        width: 4.8rem;
        height: 4.28rem;
        background: rgba(29,88,54,0.95);
        border-radius: 0.44rem;
        border: 0.02rem solid #ECEEC0;
        position: absolute;
        left: 2rem;
        top: 46%;
        transform: translateY(-50%);
        .appInfo{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .appLogo{
                width: 2rem;
                height: 2rem;
                background: url('../images/template24/icon_new_1.png') no-repeat;
                background-size: contain;
                margin-top: -0.74rem;
                border-radius: 0.48rem;
                border: 0.05rem solid #fff;
            }
            .appname{
                margin-top: 0.16rem;
                text-align: center;
                font-size: 0.48rem;
                font-family: 'Gloock', serif;
                color: #fff;
                line-height: 0.7rem;
            }
            .down{
                width: 4rem;
                margin-top: 0.55rem;
                @media (max-width:800px) {
                    width: 4.4rem;
                }
            }
        }
    }
    .foot{
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 0.8rem;
        background: #1A1A1A;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .copyRight{
            font-size: 0.16rem;
            color: #fff;
            opacity: 0.5;
            margin-left: 0.6rem;
        }
        .links{
            margin-right: 0.6rem;
            a{
                color: #fff;
                opacity: 0.5;
                &:hover{
                    opacity: 1;
                }
                &:nth-child(2){
                    padding: 0 0.2rem;
                    margin: 0 0.2rem;
                    border-right: 1px solid rgba(255, 255, 255, 0.5);
                    border-left: 1px solid rgba(255, 255, 255, 0.5);
                }
            }
        }
    }
    .privacy{
        width: 70%;
        height: calc(100vh - 2rem);
        margin: 0 auto;
        margin-top: 0.9rem;
        background: rgba(29,88,54,0.95);
        border-radius: 0.44rem;
        border: 0.02rem solid #ECEEC0;
        padding: 0 0.6rem;
        .title{
            text-align: center;
            height: 1.2rem;
            line-height: 1.2rem;
            border-bottom: 1px solid rgba(236, 238, 192, 0.3);
            font-size: 0.48rem;
            color: #ECEEC0;
            font-family: 'Gloock', serif;
        }
        .cont{
            padding: 0.2rem 0 0.6rem;
            height: calc(100% - 1.6rem);
            overflow: auto;
            color: #ECEEC0;
            p{
                margin-bottom: 0.24rem;
            }
            .subTitle{
                font-size: 0.3rem;
                margin-bottom: 0.14rem;
                font-size: 0.3rem;
            }
        }
    }
}
.template24{
    @media (max-width:880px) {
        .content{
            left: 50%;
            top: 50%;
            transform: translate(-50%,0);
            width: 6.08rem;
            height: 4.28rem;
        }
        .foot{
            flex-direction: column;
            height: 1.46rem;
            align-items: center;
            justify-content: center;
            .copyRight{
                margin: 0;
                margin-bottom: 0.2rem;
                font-size: 0.2rem;
            }
            .links{
                margin: 0;
                text-align: center;
                a{
                    font-size: 0.24rem;
                }
            }
        }
        .privacy{
            width: auto;
            margin: 0.7rem 0.32rem 0;
            box-sizing: border-box;
            padding:0 0.3rem;
            height: calc(100vh - 2.5rem);
        }
    }
}
.template25{
    background: url('../images/template24/icon2_1.png') no-repeat;
    background-position-x: center;
    background-size: cover;
    font-family: 'Chakra Petch', sans-serif;
    .content{
        left: 50%;
        top: 40%;
        transform: translate(-50%,-50%);
        width: 7.8rem;
        height: 4.4rem;
        background: #fff;
        .appInfo{
            .appname{
                color: #000;
                font-weight: bold;
                font-family: 'Chakra Petch', sans-serif;
                margin-top: 0;
            }
            .subName{
                font-size: 0.24rem;
                color: #000;
            }
            .down{
                width: 4.08rem;
                height: 1.2rem;
                background: #296EF2;
                border-radius: 0.12rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 0.2rem;
                img{
                    width: 2.76rem;

                }
            }
        }

    }
    .foot{
        justify-content: center;
        .copyRight{
            padding-right: 0.2rem;
            margin-right: 0.2rem;
            border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
    .privacy{
        background: #fff;

        .title{
            color: #000;
            border: none;
            font-size: 0.4rem;
            font-family: 'Chakra Petch', sans-serif;
        }
        .cont{
            color: #000;
        }

    }
}
.template25-page{
    @media (max-width:880px) {
        .privacy{
            margin: 0;
            border: none;
            border-radius: 0;
            height: 100%;
        }
        .foot{
            display: none;
        }

    }
}
.template26{
    background: url('../images/template24/icon3_1.webp') no-repeat;
    background-position-x: 28%;
    background-size: cover;
    font-family: 'Blinker', sans-serif;
    padding-bottom: 1.4rem;
    .content{
        width: 6.32rem;
        height: 3.92rem;
        background: #F15A34;
        margin-left: 2rem;
        margin-top: 1.7rem;
        position: relative;
        .appInfo{
            display: flex;
            flex-direction: column;
            align-items: center;
            .appLogo{
                width: 1.64rem;
                height: 1.64rem;
                border-radius: 50%;
                margin-top: -0.82rem;
                background: #fff;
            }
            .appname{
                font-size: 0.48rem;
                font-weight: 600;
                color: #fff;
            }
            .subName{
                font-size: 0.24rem;
                color: #fff;
            }
            .down{
                width: 4.72rem;
                height: 0.96rem;
                background: #000;
                border-radius: 0.48rem;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 0.4rem;
                img{
                    width: 2.76rem;
                    height: 0.66rem;
                }
            }
        }
    }
    .linkBox{
        margin-left: 2rem;
        margin-top: 0.16rem;
        width: 6.32rem;
        display: flex;
        justify-content: space-between;
        a{
            width: 2rem;
            height: 0.8rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background: #fff;
            color: #F15A34;

            &:hover{
                background: #FFE6E0;
            }
            .box1{
                width: 0.38rem;
                height: 0.46rem;
                margin: 0 0.2rem;
                background: url('../images/template24/icon3_3.png') no-repeat;
                background-size: contain;
            }
            .box2{
                width: 0.38rem;
                height: 0.46rem;
                margin: 0 0.2rem;
                background: url('../images/template24/icon3_4.png') no-repeat;
                background-size: contain;
            }
            .box3{
                width: 0.46rem;
                height: 0.38rem;
                margin: 0 0.2rem;
                background: url('../images/template24/icon3_5.png') no-repeat;
                background-size: contain;
            }
        }
    }
    .foot{
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 1.1rem;
        background: url('../images/template24/icon3_6.png') no-repeat;
        background-position-x: center;
    }
}
.template26{
    @media (max-width:800px) {
        .content{
            width: 6.32rem;
            height: 5.48rem;
            margin: 2.4rem auto 0;
            .appInfo{
                .appname{
                    margin-top: 0.3rem;
                    font-size: 0.6rem;
                }
                .subName{
                    width: 80%;
                    margin-top: 0.3rem;
                    font-size: 0.36rem;
                    text-align: center;
                }
            }

        }
        .linkBox{
            width: 6.32rem;
            margin: 0.2rem auto 0;
            flex-direction: column;
            a{
                width: 100%;
                margin-bottom: 0.2rem;
            }
        }
    }
}
.template26-page{
    width: 70%;
    margin: 0 auto;
    font-family: 'Blinker', sans-serif;
    @media (max-width:800px) {
        width: 100%;
        padding: 0 0.4rem;
        margin-top: 0.4rem;
    }
    .privacy{
        position: relative;
        .title{
            width: 100%;
            font-size: 0.6rem;
            font-weight: 600;
            color: #F15A34;
            line-height: 1rem;
            padding-bottom: 0.1rem;
            border-bottom: 0.04rem solid #F15A34;
            margin-bottom: 0.2rem;
        }
        .cont{
           p{
               margin-bottom: 0.32rem;
           }
           .subTitle{
               font-size: 0.3rem;
               color: #F15A34;
               margin-bottom: 0.2rem;
           }
        }
    }
}
.template27{
    background: #000;
    overflow: auto;
    .bg{
        position: absolute;
        width: 100%;
        height: 100%;
        border-top: 8vw solid rgba(237,27,27,0.9);
        border-right: 15vw solid rgba(237,27,27,0.9);
        border-bottom: 8vw solid rgba(237,27,27,0.9);
        border-left: 15vw solid rgba(237,27,27,0.9);
        z-index: 1;
        .links{
            position: absolute;
            bottom: -0.6rem;
            right: 0;
            a{
                margin-left: 0.3rem;
                color: #fff;
                font-size: 0.2rem;
            }
        }
        @media (max-width:1340px) {
            border-top: 13vw solid rgba(237,27,27,0.9);
            border-bottom: 13vw solid rgba(237,27,27,0.9);
        }
        @media (max-width:760px) {
            border-top: 22vw solid rgba(237,27,27,0.9);
            border-bottom: 22vw solid rgba(237,27,27,0.9);
        }

    }
    .content{
        // background: url('../images/template24/icon4_1.png') no-repeat;
        background-size: contain;
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 0.1rem;
        position: relative;
        .absolute{
            position: absolute;
        }
        img{
            width: 100%;
            height: 3rem;
            flex-shrink: 0;
            margin-bottom: 0.1rem;
        }
        .img2{
            flex-shrink: 0;
            margin-top: 0.1rem;
            margin-bottom: 0;
            // margin-top: auto;
        }
        .peading{
            line-height: 0.7;
        }
        .bottomBox{
            position: relative;
        }
        .down{
            position: absolute;
            top: -0.8rem;
            right: 18%;
            width: 3.56rem;
            height: 0.96rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background: red;
            z-index: 4;
            .downImg{
                width: 2.76rem;
                height: 0.66rem;
            }
        }
        .border{
            width: 64%;
            height: 0.2rem;
            background: red;
            margin: 0 auto;
        }
        .appName{
            // width: 70%;
            width: 64%;
            // border-top: 0.2rem solid red;
            // border-bottom: 0.2rem solid red;
            color: #fff;
            height: 100%;
            // overflow: auto;
            box-sizing: border-box;
            // margin: 0.2rem 0;
            margin-bottom: 0.8rem;
            .textContainer{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 0.8;
            }
        }
    }
}
.template27-page{
    background: #000;
    color: #fff;
    overflow: hidden;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    .privacy{
        height: 100%;
        overflow: hidden;
        width: 60%;
        margin: 0 auto;
        padding: 0 0.4rem;
        border-right: 0.01rem solid rgba(237,27,27,0.9);
        border-left: 0.01rem solid rgba(237,27,27,0.9);
        @media (max-width:800px) {
            width: 100%;
            border: none;
        }
        .title{
            font-size: 0.8rem;
            border-bottom: 0.16rem solid rgba(237,27,27,0.9);
            margin-bottom: 0.2rem;
            margin-top: 0.14rem;
        }
        .cont{
            height: 100%;
            padding-bottom: 2rem;
            overflow: auto;
        }
    }
}
.template28{
    background: url('../images/template24/icon5_1.png');
    .icon2{
        width: 10.80rem;
        height: 100vh;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../images/template24/icon5_2.png') no-repeat;
        // background-size: cover;
        background-position-x: left;
    }
    .icon3{
        width: 4.64rem;
        height: 4.64rem;
        position: absolute;
        bottom: 0;
        left: 0;
        background: url('../images/template24/icon5_3.png') no-repeat;
        background-size: cover;
        background-position-x: left;
        .cop{
            font-size: 0.24rem;
            color: rgba(0,0,0,0.8);
            position: absolute;
            bottom: .57rem;
            left: 0.7rem;
        }
    }
    .icon4{
        width: 2.6rem;
        height: 2.6rem;
        position: absolute;
        top: 0rem;
        right: 0;
        background: url('../images/template24/icon5_5.png') no-repeat;
        background-size: cover;
        background-position-x: left;

    }
    .icon5{
        width: 4.66rem;
        height: 4.66rem;
        position: absolute;
        top: 0.5rem;
        left: 0;
        background: url('../images/template24/icon5_4.png') no-repeat;
        background-size: cover;
        background-position-x: left;
    }
    .text{
        width: 8rem;
        position: absolute;
        top: 0.9rem;
        left: 0.8rem;
        font-size: 1.1rem;
        font-family: BirchStd;
        color: rgba(0,0,0,0.8);
        line-height: 1.1rem;
        z-index: 2;
    }
    .links{
        position: absolute;
        right: 0.8rem;
        top: 1rem;
        display: flex;
        align-items: center;
        div{
            font-size: 0.6rem;
        }
        a{
            font-size: 0.6rem;
            color: rgba(0,0,0,0.8);
            text-decoration: underline;
            &:hover{
                color: #000;
            }
        }
    }
}
.template29{
    background: url('../images/template29/icon_1.png');
    background-size: cover;
    overflow: hidden;
    height: 100vh;
    .box1{
        width: 6.94rem;
        height: 5.77rem;
        max-height: 60%;
        background: url('../images/template29/icon_3.png') no-repeat;
        background-size: contain;
        position: absolute;
        left: 16%;
        top: 12%;
        z-index: 1;
        .download{
            position: absolute;
            bottom: -1.14rem;
            left: 0;
            width: 3.2rem;
            height: 0.96rem;
        }
    }
    .box2{
        width: 8rem;
        height: 8rem;
        max-height: 74%;
        background: url('../images/template29/icon_2.png') no-repeat;
        background-size: contain;
        position: absolute;
        left: calc(16% + 4.8rem);
        top: 12%;
    }
    .links{
        margin-top: 0.4rem;
        margin-left: 0.6rem;
        display: flex;
        align-items: center;
        .newLine{
            width: 0.02rem;
            height: 0.16rem;
            background: #1D192F;
            opacity: 0.3;
        }
        a{
            padding: 0 0.2rem;
            color: #1D192F;
            font-size: 0.24rem;
            &:hover{
                color: #000;
            }
        }
    }

    .privacy{
        width: 80%;
        height: 80%;
        margin: 5% auto 0;
        background: rgba(255,255,255,0.6);
        box-shadow: 0px 8px 12px 0px rgba(130,0,52,0.12);
        border-radius: 0.24rem;
        backdrop-filter: blur(0.14rem);
        overflow: hidden;
        position: relative;

        .title{
            width: 6.5rem;
            height: 4.5rem;
            background: url('../images/template29/icon_4.png') no-repeat;
            background-size: contain;
            position: absolute;
            left: 0;
            top: -0.6rem;
        }
        .title1{
            background: url('../images/template29/icon_5.png') no-repeat;
            background-size: contain;
        }
        .cont{
            margin-top: 2.3rem;
            margin-left: 3rem;
            margin-right: 0.6rem;
            height: 60%;
            padding-bottom: 0.6rem;
            overflow: auto;
            p{
                margin-bottom: 0.32rem;
            }
            .subTitle{
                font-size: 0.28rem;
                font-weight: bold;
                color: #1D192F;
                line-height: 0.4rem;
                margin-bottom: 0.2rem;
            }
        }
    }
}
.template29{
    @media (max-width:800px) {
    background: url('../images/template29/icon_7.png');
    background-size: cover;
        .box2{
            width: 100%;
            height: 9.3rem;
            background: url('../images/template29/icon_9.png') no-repeat;
            background-size: contain;
            left: 0;
            top: 0.8rem;
        }
        .box1{
            width: 6.2rem;
            height: 2.9rem;
            background: url('../images/template29/icon_10.png');
            background-size: contain;
            left: 50%;
            transform: translate(-50%);
            top: 10.3rem;
            .download{
                left:50%;
                transform: translate(-50%);
                top: 2.4rem;
            }
        }
        .links{
            position: absolute;
            bottom: 0.3rem;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            margin: 0;
            justify-content: center;
            a{
                font-size: 0.24rem;
            }
        }
        .privacy{
            margin: 0.92rem 0.28rem;
            width: auto;
            height: calc(100% - 1.8rem);
           .title{
               width: 5.24rem;
               height: 3.61rem;

           }
           .cont{
               width: 100%;
               padding: 0 0.4rem 0 1rem;
               margin-left: 0;
               margin-top: 2.5rem;
               height: calc(100% - 2.6rem);
           }

        }

    }
    .icon8{
        width: 2.38rem;
        height: 1.89rem;
        background: url('../images/template29/icon_8.png') no-repeat;
        background-size: contain;
        position: fixed;
        right: 0;
        bottom: 0;
    }

}
.template30{
    position: relative;
    padding: 0.4rem 1.2rem;
    overflow: auto;
    .logoIco{
        position: absolute;
        left: 1.2rem;
        top: 0.4rem;
        width: 0.8rem;
        height: 0.8rem;
        background: url('../images/template29/icon30_7.jpeg') no-repeat;
        background-size: contain;

    }
    .links{
        position: absolute;
        right: 1.2rem;
        top: 0.4rem;
        a{
            font-size: 0.26rem;
            line-height: 0.36rem;
            color: #1A283D;
            padding: 0.06rem 0.08rem;
            border: 0.01rem solid rgba(0, 0, 0, 0.2);
            margin-left: 0.2rem;
            border-radius: 0.04rem;
        }
    }
    .centent{
        display: flex;
        justify-content: flex-start;
        margin-top: 1rem;
        .appNameBox{
            margin-right: 1rem;
            color: #1A283D;
            margin-top: 1.4rem;
            .appName{
                font-size: 0.56rem;
                font-weight: 900;
            }
            .myLine{
                width: 1.6rem;
                height: 0.04rem;
                background: #FAD200;
                margin-top: 0.2rem;
            }
            .title{
                font-size: 0.32rem;
                line-height: 0.48rem;
                margin-top: 0.4rem;
            }
            .down{
               width: 2.55rem;
               height: 0.8rem;
               margin-top: 0.6rem;
            }
            .copyName{
                font-size: 0.16rem;
                color: #1D192F;
                opacity: 0.5;
                margin-top: 0.7rem;
                border-top: 0.01rem solid #ccc;
                padding-top: 0.1rem;
            }
        }
    }
    .swiper1{
        position: relative;
        width: 9.4rem;
        height: 6.8rem;
        flex-shrink: 0;
        // top: 2rem;
        // right: 2rem;
        .newBox{
            width: 8rem;
            height: 4rem;
            position: absolute;
            background: #000;
            bottom: 0;
            right: 0;
        }
    }
    .swiperBox{
        width: 9rem;
        height: 6.4rem;
        left: 0;
        top: 0;
        margin: 0;
        .slide1{
            background: url('../images/template29/icon30_1.png') no-repeat;
            background-size: contain;
        }
        .slide2{
            background: url('../images/template29/icon30_2.png') no-repeat;
            background-size: contain;
        }
        .slide3{
            background: url('../images/template29/icon30_3.png') no-repeat;
            background-size: contain;
        }
        .swiper-scrollbar{
            position: absolute;
            // transform: rotate(90deg);
        }
    }
}
.template30-page{
    overflow: hidden;
    height: 100vh;
    background: linear-gradient(to right, #FAD200 0%,#FAD200 36%, #fff 36%,#fff 100%);
    .copyName{
        position: absolute;
        bottom: 1rem;
        left: 1.5rem;
        font-size: 0.16rem;
        color: #1D192F;
        opacity: 0.5;
        padding-top: 0.1rem;
        border-top: 0.01rem solid rgba(0, 0, 0, .1);
    }
    .links{
        .activeA{
           background: #FAD200;
           border: 0.01rem solid #FAD200;
        }
    }
    .privacy{
        overflow: hidden;
        width: 80%;
        margin: 0 auto;
        // position: relative;

        .titleBox{
            margin-top: 1rem;
            .title{
                font-size: 0.64rem;
                font-weight: bold;
                color: #1A283D;
            }
            .newLine{
                width: 100%;
                height: 0.04rem;
                background: #000;
            }
        }
        .cont{
            margin-left: 34%;
            margin-top: -0.4rem;
            overflow: auto;
            height: calc(100vh - 3rem);
            padding-bottom: 0.6rem;
            .pact-cont{
                p{
                    margin-bottom: 0.36rem;
                    color: rgba(29, 25, 47, 0.5);
                    line-height: 0.36rem;
                }
                .subTitle{
                    font-size: 0.28rem;
                    font-weight: bold;
                    line-height: 0.42rem;
                    margin-bottom: 0.2rem;
                    color: #1D192F;
                }
            }
        }
    }
    @media (max-width:700px) {
        background: linear-gradient(to right, #FAD200 0%,#FAD200 33%, #fff 33%,#fff 100%) !important;
        .copyName{
            display: none;
        }
    }
}
.template30{
    @media (max-width:700px) {
        padding: 1rem 0.5rem;
        background: #fff;
        .links{
            position: fixed;
            bottom: 0;
            top: auto;
            right: 0;
            width: 100%;
            height: 1rem;
            border-top: 0.01rem solid rgba(0, 0, 0, 0.2);
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            a{
                flex: 1;
                margin-left: 0;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                font-size: 0.26rem;
                &:nth-child(2){
                    border-right: 0.01px solid rgba(0, 0, 0, 0.1);
                    border-left: 0.01px solid rgba(0, 0, 0, 0.1);
                }
            }
        }
        .logoIco{
            left: auto;
            right: 0.5rem;
        }
        .appNamePage{
            position: absolute;
            right: 1.4rem;
            top: 0.6rem;
            font-size: 0.32rem;
            font-weight: 900;
            color: #1A283D;
        }
        .centent{
            flex-direction: column;
            .appNameBox{
                margin-top: 0;
                .copyName{
                    display: none;
                }
            }
            .swiper1{
                width: 100%;
                height: 6.6rem;
                margin-top: 0.5rem;
                .newBox{
                    width: 6.1rem;
                    height: 3.35rem;
                }
                .swiperBox{
                    width: 6.2rem;
                    height: 6.2rem;
                    .slide1{
                        background: url('../images/template29/icon30_4.png') no-repeat;
                        background-size: contain;
                    }
                    .slide2{
                        background: url('../images/template29/icon30_5.png') no-repeat;
                        background-size: contain;
                    }
                    .slide3{
                        background: url('../images/template29/icon30_6.png') no-repeat;
                        background-size: contain;
                    }
                }
            }
        }
        .privacy{
            width: 100%;
            padding-left: 0.5rem;
            .titleBox{
                margin-top: 0.5rem;
            }
            .cont{
                margin-left: 33%;
                margin-top: 0;
                .pact-cont{
                    padding-bottom: 1.6rem;
                    p{
                        margin-bottom: 0.36rem;
                        color: rgba(29, 25, 47, 0.5);
                        line-height: 0.36rem;
                    }
                    .subTitle{
                        font-size: 0.28rem;
                        font-weight: bold;
                        line-height: 0.42rem;
                        margin-bottom: 0.2rem;
                        color: #1D192F;
                    }
                }
            }
        }
    }
}
.template31{
    background: #1C9D77;
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 100vh;
    padding: 1rem 0;
    min-width: 14.7rem;
    .content{
        width: 14.72rem;
        height: 8.6rem;
        // max-height: 80%;
        background: url('../images/template29/icon31_1.png') no-repeat;
        background-size: contain;
        .box{
            margin-top: 2.26rem;
            margin-left: 2.43rem;
            width: 7rem;
        }
        .appName{
            font-family: "Syncopate", sans-serif;
            font-size: 1rem;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 1.04rem;

        }
        .downBox{
           display: flex;
           align-items: center;
           justify-content: flex-start;
           margin-top: 0.7rem;
           .down{
               width: 4rem;
               height: 1.2rem;
               margin-right: 0.3rem;
           }
           .links{
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               height: 1.2rem;
               a{
                   font-size: 0.22rem;
                   font-family: "Syncopate", sans-serif;
                   font-weight: bold;
                   color: #fff;
                   line-height: 0.24rem;
                   text-decoration: underline;
               }
           }
        }
    }
    @media (max-width:500px) {
        padding: 0.3rem 0.48rem;
        width: auto;
        min-width: auto;
        height: 100vh;
        .bg1{
            width: 100%;
            height: 1.2rem;
            background: url('../images/template29/icon31_3.png') no-repeat;
        background-size: contain;
        margin-bottom: 0.29rem;
        }
        .bg2{
            width: 100%;
            height: 1.2rem;
            background: url('../images/template29/icon31_4.png') no-repeat;
        background-size: contain;
        }
        .bg3{
            width: 100%;
            height: 2.69rem;
            background: url('../images/template29/icon31_5.png') no-repeat;
            background-size: contain;
            position: fixed;
            bottom: 0.46rem;
        }
        .content{
            background: none;
            width: 100%;
            height: 100%;
            .box{
                width: auto;
                margin: 0;
                .appName{
                    margin-top: 0.66rem;
                }
                .downBox{
                    flex-direction: column;
                    align-items: flex-start;
                    .links{
                        margin-top: 0.2rem;
                        a{
                            margin-top: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
.template31-page{
    background: #1C9D77;
    .privacy{
        width: 66%;
        margin: 1rem auto 0;
        padding-bottom: 1.5rem;
        color: #fff;
        .title{
            font-size: 0.4rem;
            font-weight: bold;
            padding-bottom: 0.2rem;
            border-bottom: 0.01rem solid #fff;
            margin-bottom: 0.34rem;
        }
        .cont{
            p{
                font-size: 0.16rem;
                line-height: 0.28rem;
                margin-bottom: 0.32rem;
            }
            .subTitle{
                font-size: 0.22rem;
                font-weight: bold;
                margin-bottom: 0.12rem;
            }
        }
        @media (max-width:500px) {
            width: 100%;
            padding: 0 0.4rem;
            margin-top: 0.65rem;
            .title{
                width: 100%;
                padding-bottom: 0.46rem;
                margin-bottom: 0.6rem;
            }
            .cont{
                p{
                    font-size: 0.28rem;
                    line-height: 0.4rem;
                    margin-bottom: 0.4rem;
                }
                .subTitle{
                    font-size: 0.32rem;
                    font-weight: bold;
                    margin-bottom: 0.16rem;
                }
            }
        }
    }
}
.template32{
    background: url('../images/template32/icon_4.png') no-repeat;
    background-size: cover;
    background-position-x: center;
    .appName{
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-top: 0.3rem;
        .logo{
            width: 0.8rem;
            height: 0.8rem;
            background: url('../images/template32/icon_11.png') no-repeat;
            background-size: contain;
            margin-right: 0.1rem;
        }
        .title{
            font-size: 0.4rem;
            font-weight: 500;
            color: #1A283D;
        }
    }
    .content{
        width: 10.08rem;
        margin: 0 auto;
        margin-top: 2%;
        .appNameTitle{
            width: 100%;
            height: 3.84rem;
            background: url('../images/template32/icon_7.png') no-repeat;
            background-size: contain;
        }
        .down{
            width: 3.2rem;
            height: 0.96rem;
            margin: 0.43rem auto;
        }
        .newAppName,.newLinks{
            display: none;
        }
        .links{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 1rem;
            a{
                padding: 0.06rem 0.15rem;
                border: 0.01rem solid rgba(0, 0, 0, 0.2);
                border-radius: 0.04rem;
                color: #1A283D;
                font-size: 0.28rem;
                font-family: 'Mulish', sans-serif;
                &:nth-child(2){
                    margin: 0 0.2rem;
                }
            }
        }
    }
    .copyTitle{
        position: absolute;
        bottom: 0.3rem;
        left: 1rem;
        font-size: 0.16rem;
        color: #1D192F;
    }
    @media (max-width:500px) {
        background: url('../images/template32/icon_2.png') no-repeat;
        background-size: contain;
        .appName{
            display: none;
        }
        .content{
            width: 100%;
            margin-top: 4rem;
            .appNameTitle{
                width: 100%;
                height: 3.54rem;
                background: url('../images/template32/icon_6.png') no-repeat;
                background-size: contain;
            }
            .links{
                display: none;
            }
            .newAppName{
                width: 4.8rem;
                height: 1.28rem;
                background: rgba(230, 208, 255, 0.3);
                border-radius: 0.64rem;
                margin: 0.96rem auto 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .logo{
                    width: 1rem;
                    height: 1rem;
                    border-radius: 0.28rem;
                    background: url('../images/template32/icon_11.png') no-repeat;
                    background-size: contain;
                    margin-right: 0.24rem;

                }
                .title{
                    font-size:  0.4rem;
                    font-weight: 600;
                    color: #B683FF;
                }
            }
        }
        .newLinks{
            width: 0.48rem;
            height: 0.48rem;
            position: fixed;
            top: 1.08rem;
            right: 0.48rem;
            background: url('../images/template32/icon_10.png') no-repeat;
            background-size: contain;
        }
        .linksBox{
            position: fixed;
            top: 0;
            left:0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            background: rgba(0, 0, 0, .1);
            z-index: 2;
        }
        .linksShow{
            position: fixed;
            width: 80%;
            height: 100%;
            top: 0;
            right: 0;
            background: #fff;
            display: flex;
            flex-direction: column;
            padding-top: 4rem;
            .closeIcon{
                width: 0.48rem;
                height: 0.48rem;
                position: fixed;
                top: 1.08rem;
                right: 0.48rem;
                background: url('../images/template32/icon_10.png') no-repeat;
                background-size: contain;
            }
            a{
                height: 1.2rem;
                line-height: 1.2rem;
                text-align: center;
                font-size: 0.4rem;
                color: #080808;
                &:hover{
                    font-weight: bold;
                    color: #B683FF;
                    background: rgba(230, 208, 255, 0.2);
                }
            }
        }
        .copyTitle{
            width: 100%;
            left: 50%;
            transform: translate(-50%);
            bottom: 1rem;
            text-align: center;
        }
    }
}
.template32-page{
    height: 100vh;
    background: url('../images/template32/icon_1.png') no-repeat;
    background-size: 100% 100%;
    .appName{
        position: absolute;
        top: 0.3rem;
        left: 1rem;
        display: flex;
        align-items: center;
        .logo{
            width: 0.8rem;
            height: 0.8rem;
            background: url('../images/template32/icon_11.png') no-repeat;
            background-size: contain;
            margin-right: 0.1rem;
        }
        .title{
            font-size: 0.4rem;
            font-weight: 500;
            color: #1A283D;
        }
    }
    .links{
        position: absolute;
        right: 1rem;
        top: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        a{
            padding: 0.06rem 0.15rem;
            border: 0.01rem solid rgba(0, 0, 0, 0.2);
            border-radius: 0.04rem;
            color: #1A283D;
            font-size: 0.28rem;
            font-family: 'Mulish', sans-serif;
            &:nth-child(2){
                margin: 0 0.2rem;
            }
        }
    }
    .privacy{
        width: 62%;
        margin: 0 auto;
        .title{
            width: 8.44rem;
            height: 1.55rem;
            background: url('../images/template32/icon_8.png') no-repeat;
            background-size: contain;
            margin: 2rem auto 0;
        }
        .title2{
            background: url('../images/template32/icon_9.png') no-repeat;
            background-size: contain;
        }
        .cont{
            height: calc(100vh - 3rem);
            overflow: auto;
            padding-bottom: 2rem;
            margin-top: 0.36rem;
            p{
                font-size: 0.24rem;
                line-height: 0.36rem;
                margin-bottom: 0.32rem;
                color: rgba(26, 40, 61, 0.5);
            }
            .subTitle{
                font-size: 0.28rem;
                font-weight: bold;
                margin-bottom: 0.16rem;
                color: #1A283D;
            }
        }

    }
    @media (max-width:500px) {
        background: url('../images/template32/icon_2.png') no-repeat;
        background-size: contain;
        .appName,.links{
            display: none;
        }
        .newLinks{
            width: 0.48rem;
            height: 0.48rem;
            position: fixed;
            top: 1.08rem;
            right: 0.48rem;
            background: url('../images/template32/icon_10.png') no-repeat;
            background-size: contain;
        }
        .linksBox{
            position: fixed;
            top: 0;
            left:0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100vh;
            background: rgba(0, 0, 0, .1);
        }
        .linksShow{
            position: fixed;
            width: 80%;
            height: 100%;
            top: 0;
            right: 0;
            background: #fff;
            display: flex;
            flex-direction: column;
            padding-top: 4rem;
            .closeIcon{
                width: 0.48rem;
                height: 0.48rem;
                position: fixed;
                top: 1.08rem;
                right: 0.48rem;
                background: url('../images/template32/icon_10.png') no-repeat;
                background-size: contain;
            }
            a{
                height: 1.2rem;
                line-height: 1.2rem;
                text-align: center;
                font-size: 0.4rem;
                color: #080808;
                &:hover{
                    font-weight: bold;
                    color: #B683FF;
                    background: rgba(230, 208, 255, 0.2);
                }
            }
        }
        .privacy{
            width: 100%;
            padding: 0 0.7rem;
            .title{
                width: 6.4rem;
                height: 1.49rem;
                margin: 3.6rem auto 0;
            }
            .cont{
                height: calc(100vh - 3.8rem);
            }
        }
    }
}
.template33{
    background: url('../images/template32/icon1_1.png') no-repeat;
    background-size: auto;
    background-position-x:  right;
    min-width: 1400px;
    .links{
        position: absolute;
        right: 0;
        top: 0.1rem;
        display: flex;
        flex-direction: column;
        z-index: 2;
        a{
            width: 240px;
            height: 76px;
            font-size: 0.36rem;
            color: #000000;
            // line-height: 0.63rem;
            // margin-bottom: 0.1rem;
            font-family: 'Dongle', sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
   .content{
       width: 12.14rem;
       margin: 0 auto;
       .titleName{
           margin-top: 0.6rem;
           width: 100%;
           height: 6.7rem;
           background: url('../images/template32/icon1_6.png') no-repeat;
            background-size: contain;
       }
       .down{
           width: 5.72rem;
           margin: 0.3rem auto;
           padding-left: 0.8rem;
       }
   }
   @media (max-width:500px) {
    background: url('../images/template32/icon1_4.png') no-repeat;
    background-size: cover;
    width: 100%;
    min-width: 100%;
    .links{
        position: absolute;
        right: 0;
        top: 0.94rem;
        display: flex;
        flex-direction: column;
        z-index: 2;
        a{
            width: 2.00rem;
            height: 0.67rem;
            font-size: 0.36rem;
            color: #000000;
            // line-height: 0.63rem;
            // margin-bottom: 0.1rem;
            font-family: 'Dongle', sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .content{
        width: 100%;

        .titleName{
            width: 6.21rem;
            height: 4.6rem;
            margin: 5rem auto 0;
            background: url('../images/template32/icon1_2.png') no-repeat;
            background-size: contain;
        }
        .down{
            width: 5.02rem;
            margin: 1rem 0 0 1.7rem;
        }
    }
   }
}
.template33-page{
    // height: 100vh;
    background: url('../images/template32/icon1_3.png') no-repeat;
    font-family: 'Dongle', sans-serif;
    .privacy{
        .title{
            font-size: 1rem;
            line-height: 1.2rem;
            font-weight: bold;
            margin-left: 2.5rem;
        }
        .cont{
            width: 92%;
            height: 8.68rem;
            background: url('../images/template32/icon1_7.png') no-repeat;
            background-size: cover;
            margin: 0 auto;
            height: calc(100vh - 1.6rem);
            overflow: auto;
            padding: 0.4rem 2.4rem 1rem;
            // margin-bottom: 3rem;
            p{
                font-size: 0.3rem;
                line-height: 0.44rem;
                color: #262626;
                margin-bottom: 0.24rem;
            }
            .subTitle{
                font-weight: bold;
                margin-bottom: 0.02rem;
            }
        }
    }
    @media (max-width:500px) {
        background: url('../images/template32/icon1_5.png') no-repeat;
        .privacy{
            .title{
                font-size: 0.8rem;
                line-height: 1.1rem;
                font-weight: bold;
                margin-left: 1rem;
                margin-top: 1.2rem;
            }
            .cont{
                width: 100%;
                background: url('../images/template32/icon1_8.png') no-repeat;
                background-size: contain;
                height: 13rem;
                padding: 0.4rem 1.07rem 1rem;
                margin-bottom: 0.4rem;
                p{
                    font-size: 0.34rem;
                }
            }
        }
    }
}
.template34{
    // height: 10.80rem;
    background: url('../images/template32/icon2_1.png') no-repeat;
    background-size: auto;
    padding: 0 0.6rem;

    .top{
        width: 100%;
        height: 1.2rem;
        border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .appLogo{
            width: 1.72rem;
            height: 0.6rem;
            background: url('../images/template32/icon2_2.png') no-repeat;
            background-size: contain;
        }
        .newLinks{
            display: none;
        }
        .links{
            a{
                font-size: 0.24rem;
                color: #fff;
                opacity: 0.6;
                padding:0 0.4rem;
                border-right: 0.01rem solid rgba(255, 255, 255, 0.2);
                &:first-child{
                    opacity: 1;
                }
                &:last-child{
                    border: none;
                }
            }
        }
    }
    .content{
        margin: 0.23rem auto 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .title{
            width: 10.7rem;
            height: 1.83rem;
            background: url('../images/template32/icon2_3.png') no-repeat;
            background-size: contain;
        }
        p{
            font-size: 0.32rem;
            color: #fff;
            opacity: 0.5;
            line-height: 0.32rem;
        }
        .down{
            width: 3.06rem;
            height: 0.96rem;
            cursor: pointer;
        }
    }
    @media (max-width:700px) {
        background: url('../images/template32/icon2_8.png') no-repeat;
        background-size: contain;
        padding: 0;
        .top{
            justify-content: center;
            position: relative;
            .newTab{
                position: absolute;
                width: 1.2rem;
                height: 1.2rem;
                background: url('../images/template32/icon2_10.png') no-repeat;
                background-size: contain;
                top: 0;
                left: 0;
                z-index: 4;
            }
            .links{
                display: none;
            }
            .newLinks{
                display: block;
                position: fixed;
                top: 0;
                left:0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100vh;
                background: rgba(0, 0, 0, .6);
                z-index: 2;

                .newAppLogo{
                    position: absolute;
                    width: 1.2rem;
                    height: 1.48rem;
                    background: url('../images/template32/icon2_11.png') no-repeat;
                    background-size: contain;
                    left: 50%;
                    transform: translate(-50%);
                    top: 2.4rem;
                }
                .linksShow{
                    width: 80%;
                    height: 100%;
                    background: #242828;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    padding-top: 5rem;
                    position: absolute;
                    a{
                        width: 100%;
                        height: 1.2rem;
                        line-height: 1.2rem;
                        border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
                        padding-left: 0.8rem;
                        color: #FFFFFF;
                        font-size: 0.32rem;
                    }
                    .activeLink{
                        color: #CAFDC1;
                        background: linear-gradient(90deg, rgba(2,254,240,0) 0%, rgba(119,253,212,0.29) 59%, #CAFDC1 100%);
                    }
                }

            }
        }
        .content{
            .title{
                width: 6.7rem;
                height: 1.2rem;
                background: url('../images/template32/icon2_9.png') no-repeat;
                background-size: contain;
            }
            p{
                font-size: 0.24rem;
                margin-top: 0.2rem;
            }
            .down{
                margin-top: 0.7rem;
            }
        }

    }
}
.template34-page{
    background: url('../images/template32/icon2_5.png') no-repeat;
    background-size: auto;
    padding: 0 0.6rem;
    .top{
        width: 100%;
        height: 1.2rem;
        border-bottom: 0.01rem solid rgba(255, 255, 255, 0.2);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .appLogo{
            width: 1.72rem;
            height: 0.6rem;
            background: url('../images/template32/icon2_2.png') no-repeat;
            background-size: contain;
        }
        .links{
            a{
                font-size: 0.24rem;
                color: #fff;
                opacity: 0.6;
                padding:0 0.4rem;
                border-right: 0.01rem solid rgba(255, 255, 255, 0.2);

                &:last-child{
                    border: none;
                }
            }
            .activeLink{
                opacity: 1;
            }
        }
    }
    .privacy{
        width: 70%;
        margin: 0 auto;
        .title{
            width: 6.8rem;
            height: 0.51rem;
            background: url('../images/template32/icon2_6.png') no-repeat;
            background-size: contain;
            margin: 0.4rem auto;
        }
        .title2{
            width: 8.14rem;
            height: 0.51rem;
            background: url('../images/template32/icon2_7.png') no-repeat;
            background-size: contain;
        }
        .cont{
            height: calc(100vh - 3.4rem);
            color: #fff;
            overflow: auto;
            p{
                font-size: 0.24rem;
                opacity: 0.5;
                line-height: 0.36rem;
                margin-bottom: 0.32rem;
            }
            .subTitle{
                font-size: 0.28rem;
                line-height: 0.42rem;
                opacity: 1;
                font-weight: bold;
                margin-bottom: 0.2rem;
            }
        }
    }
    @media (max-width:700px) {
        padding: 0;
        .top{
            justify-content: center;
            .links{
                display: none;
            }
        }
        .privacy{
            width: 100%;
            padding:0 0.7rem;
            .title{
                width: 4.26rem;
                height: 0.33rem;
                background: url('../images/template32/icon2_12.png') no-repeat;
                background-size: contain;
            }
            .title2{
                width: 5.09rem;
                height: 0.33rem;
                background: url('../images/template32/icon2_13.png') no-repeat;
                background-size: contain;
            }
        }
    }
}
.template35{
    background: url('../images/template32/icon3_1.png') no-repeat;
    background-size: auto;
    background-position-x: center;
    overflow: auto;
    height: 9rem;
    min-height: 100vh;
    .content{
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 1.8rem;
        .appLogo{
            width: 2.36rem;
            height: 2.36rem;
            background: url('../images/template32/icon3_3.png') no-repeat;
            background-size: contain;
        }
        .titleName{
            font-size: 0.62rem;
            line-height: 0.77rem;
            font-family: "Akrobat-ExtraBold";
            color:#fff;
            margin-top: 0.4rem;
        }
        .titleSub{
            width: 4.96rem;
            height: 0.6rem;
            background: url('../images/template32/icon3_5.png') no-repeat;
            background-size: contain;
        }
        .down{
            width: 4rem;
            height: 1.2rem;
            margin-top: 0.36rem;
        }
        .reserved{
            display: none;
        }
        .links{

            display: flex;
            justify-content: start;
            align-items: center;
            margin-top: 0.4rem;
            .newLine{
                width: 0.01rem;
                height: 0.1rem;
                background: #fff;
                opacity: 0.2;
            }
            a{
                font-size: 0.14rem;
                color: #fff;
                opacity: 0.5;
                margin: 0 0.2rem;
                &:hover{
                    opacity: 1;
                }
            }
        }
    }
    @media (max-widtH:700px) {
        background: #000;
        .newPage{
            height: 100%;
            background: url('../images/template32/icon3_2.png') no-repeat;
            background-size: contain;
        }
        .content{
            width: 100%;
            top: 4.2rem;
            padding-bottom: 0.5rem;
            .reserved{
                display: block;
                font-size: 0.2rem;
                color: #fff;
                opacity: 0.5;
                margin-top: 3rem;
            }
            .links{
                margin-top: 0.2rem;
                a{
                    font-size: 0.2rem;
                }
            }
        }

    }
}
.template35-page{
    background: #1A1A1A;
    .privacy{
        width: 60%;
        margin: 1rem auto 0;
        color: #fff;
        padding-bottom: 0.5rem;
        .title{
            font-size: 0.4rem;
            padding-bottom: 0.2rem;
            border-bottom: 0.01rem solid rgba(255,255,255,0.3);
            margin-bottom: 0.34rem;
        }
        .cont{
            p{
                font-size: 0.16rem;
                line-height: 0.28rem;
                opacity: 0.6;
                margin-bottom: 0.32rem;
            }
            .subTitle{
                opacity: 1;
                font-size: 0.22rem;
                margin-bottom: 0.12rem;
                font-weight: bold;
            }
        }
    }
    @media (max-width:700px) {
        .privacy{
            width: 100%;
            padding: 0 0.4rem;
            .cont{
                p{
                    font-size: 0.28rem;
                    line-height: 0.4rem;
                }
                .subTitle{
                    font-size: 0.32rem;
                    font-weight: bold;
                }
            }
        }
    }
}
.template36{
    height: 9rem;
    min-height: 100vh;
    background: #F7F2F2;
    .bg2{
        width: 70%;
        height: 100%;
        background: url('../images/template32/icon4_1.png') no-repeat;
        background-size: auto;
        background-position-x: right;
        position: absolute;
        right: 0;
    }
    .bg3{
        width: 66%;
        height: 100%;
        position: absolute;
        left: 0;
        background: url('../images/template32/icon4_5.png') no-repeat;
        background-size: auto;
    }
    .content{
        position: relative;
        height: 100vh;
        .appInfo{
            display: flex;
            align-items: center;
            position: absolute;
            top: 0.2rem;
            left: 0.2rem;
            .appLogo{
                width: 0.5rem;
                height: 0.5rem;
                background: url('../images/template32/icon4_7.png') no-repeat;
                background-size: contain;
                margin-right: 0.1rem;
            }
        }
        .title{
            position: absolute;
            top: 2rem;
            left: 0.6rem;
            font-size: 0.28rem;
            font-weight: 500;
            color: #B40000;
        }
        .links{
            position: absolute;
            right: 0.3rem;
            top: 0.3rem;
            a{
                margin-left: 1.44rem;
                color: #E6E6E6;
                font-size: 0.24rem;
                &:hover{
                    color: #fff;
                }
            }
        }
        .down{
            width: 3.32rem;
            height: 1.26rem;
            position: absolute;
            right: 0;
            bottom: 1.2rem;
            z-index: 4;
        }
    }
    @media (max-width:700px) {
        .bg2{
            width: 100%;
            height: 9.98rem;
            bottom: 0;
            background: url('../images/template32/icon4_4.png') no-repeat;
            background-size: contain;
        }
        .bg3{
            width: 100%;
            height: 6.65rem;
            top: 0;
            background: url('../images/template32/icon4_2.png') no-repeat;
            background-size: contain;
        }
        .content{
            .appInfo{
                display: none;
            }
            .title{
                top: 1.4rem;
                left: 0;
            }
            .links{
                width: 100%;
                bottom: 0.2rem;
                top: auto;
                right: auto;
                display: flex;
                justify-content: space-around;
                a{
                    margin: 0;
                    color: #F0F0F0;
                }
            }
        }
    }
}
.template36-page{
    background: #F7F2F2;
    .newLogo{
        position: fixed;
        top: 0;
        width: 100%;
        height: 0.8rem;
        background: #F7F2F2;
        div{
            width: 3.27rem;
            height: 0.64rem;
            margin: 0 auto;
            background: url('../images/template32/icon4_6.png') no-repeat;
            background-size: contain;
        }
    }
    .privacy{
        width: 60%;
        margin: 1.6rem auto 0;
        .title{
            font-size: 1.4rem;
            color: #B40000;
            line-height: 1.8rem;
            margin-bottom: 0.35rem;
        }
        .cont{
            padding-bottom: 0.5rem;
            p{
                font-size: 0.24rem;
                line-height: 0.33rem;
                color: #B40000;
                margin-bottom: 0.3rem;
            }
            .subTitle{
                font-weight: bold;
                margin-bottom: 0.2rem;
            }
        }
    }
    @media (max-width:700px) {
        .privacy{
            width: 100%;
            padding: 0 0.35rem;
            .title{
                font-size: 1rem;
                line-height: 1;
            }
        }
    }
}
.template37{
    background: url('../images/template32/icon5_1.png') no-repeat;
    background-size: auto;
    background-position-x: center;
    height: 9rem;
    min-height: 100vh;
    .content{
        width: 7.48rem;
        height: 5.12rem;
        background: url('../images/template32/icon5_4.png') no-repeat;
        background-size: contain;
        margin: 0.66rem auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .titleName{
            font-size: 0.48rem;
            line-height: 0.63rem;
            color: #6A3921;
            margin-top: 0.47rem;
        }
        .titleSub{
            text-align: center;
            font-size: 0.28rem;
            line-height: 0.37rem;
            color: #6A3921;
            margin-top: 0.22rem;
            max-width: 5.7rem;
        }
        .down{
            width: 4rem;
            height: 1.2rem;
            margin-top: 0.42rem;
        }
    }
    .footBox{
        position: fixed;
        width: 100%;
        height: 0.8rem;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(42,13,7,0.8);
        .reserved{
            font-size: 0.14rem;
            color: #fff;
            opacity: 0.5;
            padding-left: 0.4rem;
        }
        .links{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 0.4rem;
            a{
                color: #fff;
                opacity: 0.5;
                font-size: 0.14rem;
                margin: 0 0.2rem;
                &:hover{
                    opacity: 1;
                }
            }
            .newLine{
                width: 0.01rem;
                height: 0.1rem;
                background: #fff;
                opacity: 0.2;
            }
        }
    }
    @media (max-width:700px) {
        background: #1E0704;
        .newBox{
            height: 100%;
            background: url('../images/template32/icon5_2.png') no-repeat;
            background-size: contain;
            overflow: hidden;
        }
        .content{
            width: 6.86rem;
            height: 5.64rem;
            background: url('../images/template32/icon5_5.png') no-repeat;
            background-size: contain;
            .titleName{
                margin-top: 0.74rem;
            }
        }
        .footBox{
            height: 1.36rem;
            flex-direction: column;
            justify-content: center;
            .reserved{
                padding: 0;
                font-size: 0.2rem;
            }
            .links{
                padding: 0;
                margin-top: 0.1rem;
                a{
                    font-size: 0.2rem;
                }
            }
        }
    }
}
.template37-page{
    .privacy{
        width: 60%;
        height: 80%;
        background: #D7BB75;
        border: 0.04rem solid #4B1B03;
        margin: 0.6rem auto 0;
        overflow: hidden;
        padding: 0.5rem 1rem;
        .title{
            text-align: center;
            color: #6A3921;
            font-size: 0.48rem;
            line-height: 0.63rem;
            margin-bottom: 0.3rem;
            font-weight: bold;
        }
        .cont{
            height: 100%;
            overflow: auto;
            padding-bottom: 1rem;
            p{
                font-size: 0.24rem;
                line-height: 0.36rem;
                color: #6A3921;
                margin-bottom: 0.3rem;
            }
            .subTitle{
                font-size: 0.28rem;
                margin-bottom: 0.12rem;
                font-weight: bold;
            }
        }
    }
    @media (max-width:700px) {
        .privacy{
            width: 6.86rem;
            margin: 0.6rem 0.32rem 0;
            padding: 0.4rem;
            height: calc(100vh - 2rem);
        }
    }
}
.template38{
    background: url('../images/template32/icon6_1.png') no-repeat;
    background-size: auto;
    .links{
        position: absolute;
        top: 0.36rem;
        right: 0.5rem;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        border-radius: 0.16rem;
        a{
            font-size: 0.14rem;
            color: #fff;
            padding: 0.08rem 0.2rem ;
            opacity: 0.5;
            &:hover{
                opacity: 1;
            }
        }
        .newLine{
            width: 0.01rem;
            height: 0.1rem;
            background: #fff;
            opacity: 0.2;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1rem auto 0;
        .appLogo{
            width: 2.36rem;
            height: 2.36rem;
            background: url('../images/template32/icon6_3.png') no-repeat;
            background-size: contain;
        }
        .titleName{
            font-size: 0.52rem;
            font-weight: 800;
            line-height: 0.6rem;
            color: #fff;
            margin-top: 0.36rem;
        }
        .down{
            width: 4.12rem;
            height: 1.38rem;
            margin-top: 0.43rem;
        }
    }
    @media (max-width:700px) {
        background: #000000;
        .media1{
            width: 100%;
            height: 6.78rem;
            background: url('../images/template32/icon6_5.png') no-repeat;
            background-size: contain;
            position: absolute;
            top: 0;
        }
        .media2{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 5.16rem;
            background: url('../images/template32/icon6_6.png') no-repeat;
            background-size: contain;
        }
        .content{
            margin-top: 4rem;
        }
        .links{
            position: fixed;
            bottom: 0;
            height: 0.84rem;
            background: rgba(0,0,0,0.7);
            width: 100%;
            top: auto;
            right: auto;
            border-radius: 0;
            justify-content: center;
            a{
                font-size: 0.2rem;
                opacity: 0.8;
            }
        }
    }
}
.template38bg{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('../images/template32/icon6_4.png') no-repeat;
    background-position-x: center;
}
.newPage1{
    color: #fff !important;
    .wrapper{
        justify-content: flex-start;
        color: #fff;
        margin-top: 1rem;
    }
}
.template39{
    background: url('../images/template32/icon7_1.png') no-repeat;
    background-position-x: center;
    min-height: 8rem;
    height: 100vh;
    .newTab{
        position: absolute;
        top: 0.6rem;
        right: 0.68rem;
        width: 0.56rem;
        height: 0.56rem;
        background: url('../images/template32/icon7_3.png') no-repeat;
        background-size: contain;
    }
    .newLinks{
        position: absolute;
        top: 1.2rem;
        right: 0.68rem;
        background: #FFE0E7;
        border-radius: 0.14rem;
        .linksShow{
            display: flex;
            flex-direction: column;
            // align-items: center;
            justify-content: center;
            padding: 0.12rem 0.28rem;
            width: 2.4rem;
            a{
                height: 0.8rem;
                line-height: 0.8rem;
                color: #FE2E62;
                font-size: 0.24rem;
            }
            .activeLink{
                text-decoration: underline;
                font-weight: bold;
            }
        }

    }
    .content{
        margin: 5rem auto 0;
        .down{
            width: 4.8rem;
            height: 1.2rem;
            margin: 0 auto;
        }
    }
    .reserved{
        position: fixed;
        left: 50%;
        transform: translate(-50%);
        bottom: 0.3rem;
        font-size: 0.14rem;
        color: #FE2E62;
    }
    .privacy{
        background: #fff;
        width: 60%;

        height: calc(100vh - 1.8rem);
        margin: 1.22rem auto 0;
        border-radius: 0.2rem;
        padding: 0.28rem;
        overflow: hidden;
        .title{
            font-size: 0.4rem;
            line-height: 1rem;
            color: #FE2E62;
            font-weight: bold;
            text-align: center;
        }
        .cont{
            height: 100%;
            overflow: auto;
            padding-bottom: 1rem;
            p{
                color: #FE2E62;
                font-size: 0.18rem;
                line-height: 0.28rem;
                margin-bottom: 0.3rem;
            }
            .subTitle{
                margin-bottom: 0.14rem;
                font-size: 0.24rem;
                font-weight: bold;
            }
        }
    }
    @media (max-width:700px) {
        background: url('../images/template32/icon7_2.png') no-repeat;
        background-size: cover;
        .content{
            margin-top: 9rem;
        }
        .reserved{
            width: 100%;
            font-size: 0.2rem;
            text-align: center;
        }
        .privacy{
            width: 6.86rem;
            height: calc(100vh - 2.2rem);
            margin: 1.42rem auto 0;
            .cont{
                p{
                    font-size: 0.24rem;
                    margin-bottom: 0.4rem;
                    line-height: 0.38rem;
                }
                .subTitle{
                    font-size: 0.28rem;
                }
            }
        }
    }
}

.ml5{
    margin-left: .05rem;
}
.paragraph{
    // padding-bottom: 0.1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 0.24rem;
}
.lastP{
    padding-bottom: 1rem;
}

.termsContent p{
    font-size:.24rem;
    line-height: .38rem;
}
